import React, { useEffect, useState } from "react";
import { Card } from "flowbite-react";
import { USERS } from "../../config/apiConfig";
import { useQuery } from "react-query";
import axios from "../../config/axiosInstance";
import DrawerLoading from "../DrawerLoading";
import { AiOutlineFileSearch } from "react-icons/ai";
import ForbiddenQueriesPermission from "../common/ForbiddenQueriesPermission";
import { IoSearch } from "react-icons/io5";
import CompletaVeicularQuery from "../queries/CompletaVeicularQuery";
import LeilaoPositivoQuery from "../queries/autos/LeilaoPositivoQuery";

const QueriesAutoTable = () => {
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [searchTerm, setSearchTerm] = useState(""); // Novo estado para a busca

  const {
    isLoading,
    error,
    data: userQueryPermissions,
  } = useQuery(["userQueryPermissions"], async () => {
    const { data: items } = await axios.get(
      `${USERS.GET_USER_QUERY_PERMISSIONS}`
    );

    return items.data.filter((query) => query.categoryId === 3);
  });

  useEffect(() => {
    if (selectedQuery) {
      const matchingQuery = userQueryPermissions.find(
        (query) => query.id === selectedQuery?.id
      );
      setSelectedPrice(matchingQuery?.price);
    }
  }, [selectedQuery]);

  const filteredQueries = userQueryPermissions?.filter((query) =>
    query?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const queries = [
    {
      id: 19,
      name: "Auto Vistoria",
      component: (
        <CompletaVeicularQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
    },
    {
      id: 26,
      name: "Leilão Positivo",
      component: (
        <LeilaoPositivoQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
    },
  ];

  return (
    <div className="w-100 flex flex-col lg:flex-row relative">
      {isLoading && <DrawerLoading />}
      {userQueryPermissions?.length > 0 ? (
        <>
          <div className="w-full lg:w-3/12 p-4 flex flex-col gap-3">
            <h2 className="title-syncx text-lg">Consultas veiculares</h2>
            <div className="border-none rounded-md bg-secondary p-2 shadow-lg flex items-center gap-1 px-3">
              <IoSearch color="white" fontSize={20} />
              <input
                className="border-none rounded-md bg-secondary w-full placeholder-white focus:outline-none focus:border-none text-white text-sm"
                type="text"
                placeholder="Busque aqui sua consulta"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} // Atualiza o valor da busca
              />
            </div>
            <div
              className="bg-gray-200 rounded-md shadow-md p-2 overflow-y-auto flex flex-col gap-2 styled-scrollbar"
              style={{ maxHeight: "520px" }}
            >
              {filteredQueries?.map((query, index) => (
                <Card
                  key={index}
                  className={`card-query text-blue-900 ${
                    selectedQuery?.id === query.id && "active"
                  }`}
                  onClick={() => setSelectedQuery(query)}
                >
                  <span>{query.name}</span>
                </Card>
              ))}
            </div>
          </div>
          {selectedQuery ? (
            <div className={`w-full lg:w-9/12 p-4`}>
              {
                queries.find((query) => query.id === selectedQuery?.id)
                  ?.component
              }
            </div>
          ) : (
            <div className="mt-14 w-full lg:w-9/12 text-center py-10 border-dashed border-gray-300 border-2 rounded font-semibold text-gray-500 flex items-center justify-center min-h-60 flex gap-1 flex-col">
              <AiOutlineFileSearch fontSize={30} />
              <span>Escolha uma opção ao lado para realizar uma consulta.</span>
            </div>
          )}
        </>
      ) : (
        <ForbiddenQueriesPermission />
      )}
    </div>
  );
};

export default QueriesAutoTable;
