import { jwtDecode } from "jwt-decode";
import moment from "moment";
import { QUERIES } from "../config/apiConfig";
import { handleDownloadPdf } from "./pdf-exporter";
import axios from "../config/axiosInstance";

export const checkIfAuthenticated = () => {
  const token = localStorage.getItem("token");

  try {
    const decodedToken = jwtDecode(token);

    const tokenExpirationDate = new Date(decodedToken.exp) * 1000;
    const currentDate = new Date();

    return tokenExpirationDate > currentDate;
  } catch (error) {
    return false;
  }
};

export const saveUserInWindow = () => {
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  window.user = decodedToken;
};

export const calculateExpirationPeriodTest = (date) => {
  const expirationDate = moment(date);
  const today = moment();
  return expirationDate.diff(today, "days") + 1;
};

export const getFirstLettersName = (fullName) => {
  const nameParts = fullName.trim().split(" ");

  const firstLetterFirstName = nameParts[0][0];
  const firstLetterLastName =
    nameParts.length > 1 ? nameParts[nameParts.length - 1][0] : "";

  return `${firstLetterFirstName}${firstLetterLastName}`.toUpperCase();
};

export const isValidCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/[^\d]+/g, "");
  if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) return false;
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(0))) return false;
  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  return resultado === parseInt(digitos.charAt(1));
};

export const isValidCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;
  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++)
    soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
};

export const formatToReal = (number) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};

export const resetValuesForm = (values, setValue, reset) => {
  values.forEach((value) => {
    setValue(value, "");
  });
  reset();
};

export const uploadPDF = async (component, info, queryId) => {
  const file = await handleDownloadPdf(component.current);
  localStorage.setItem("pdfFile", file);
  return file;
};

export const getImageURLScore = (score) => {
  const scoreImages = [
    "/assets/score/score_1.png",
    "/assets/score/score_2.png",
    "/assets/score/score_3.png",
    "/assets/score/score_4.png",
    "/assets/score/score_5.png",
    "/assets/score/score_6.png",
    "/assets/score/score_7.png",
    "/assets/score/score_8.png",
    "/assets/score/score_9.png",
    "/assets/score/score_10.png",
    "/assets/score/score_11.png",
    "/assets/score/score_12.png",
    "/assets/score/score_13.png",
    "/assets/score/score_14.png",
    "/assets/score/score_15.png",
  ];

  const interval = 1000 / scoreImages.length;
  const index = Math.min(Math.floor(score / interval), scoreImages.length - 1);

  return scoreImages[index];
};

export const getColorScore = (score) => {
  const colors = ["#ed505b", "#FF9700", "#f2da09", "#39EA0E", "#027B01"];

  if (score < 0) score = 0;
  if (score > 1000) score = 1000;

  const index = Math.floor(score / 200);

  return colors[index];
};

export const getColorProbability = (probability) => {
  const colors = ["#027B01", "#f2da09", "#F43240"];

  if (probability < 0) probability = 0;
  if (probability > 1000) probability = 100;

  const index = Math.floor(probability / 33);

  return colors[index];
};

export const getLetterScore = (score) => {
  const letter = ["D", "C", "B", "AA", "AAA"];

  if (score < 0) score = 0;
  if (score > 1000) score = 100;

  const index = Math.floor(score / 200);

  return letter[index];
};

export const maskCEP = (cep) => {
  cep = cep?.replace(/\D/g, "");
  cep = cep?.replace(/^(\d{5})(\d)/, "$1-$2");

  return cep;
};

export const maskStateSubscription = (inscricao) => {
  inscricao = inscricao.replace(/\D/g, "");
  return inscricao.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3.$4-$5"
  );
};

export const getImageScoreSale = (score) => {
  let scoreLetter;

  if (score <= 125) {
    scoreLetter = "1";
  } else if (score <= 250) {
    scoreLetter = "2";
  } else if (score <= 375) {
    scoreLetter = "3";
  } else if (score <= 500) {
    scoreLetter = "4";
  } else if (score <= 625) {
    scoreLetter = "5";
  } else if (score <= 750) {
    scoreLetter = "6";
  } else if (score <= 875) {
    scoreLetter = "7";
  } else {
    scoreLetter = "8";
  }

  return `/assets/score/serasa/score_${scoreLetter}.png`;
};

export const calculateRiskScore = (pendencias, protestos, score) => {
  const maxScore = 1000;
  const pesoPendencias = 50;
  const pesoProtestos = 60;

  if (pendencias > 0 || protestos > 0) {
    const penalidade = pendencias * pesoPendencias + protestos * pesoProtestos;

    return Math.max(0, maxScore - penalidade);
  } else {
    if (score > 800) {
      return 850;
    }
    if (score > 600) {
      return 650;
    }
    if (score > 400) {
      return 500;
    }
    {
      return 300;
    }
  }
};

export const formatDateToYY = (input) => {
  const match = input.match(/^([A-Za-z]{3})\/(\d{4})$/);

  if (match) {
    const month = match[1]; // Mês (MMM)
    const day = match[2].slice(0, 2); // Extrai os primeiros dois dígitos do dia

    return `${month}/${day}`;
  } else {
    return input; // Retorna a string original caso o formato não seja esperado
  }
};

export const formatDateToDDMM = (dateString) => {
  const month = dateString.slice(0, 2);
  const year = dateString.slice(2);

  const shortYear = year.slice(-2);

  return `${month}/${shortYear}`;
};
