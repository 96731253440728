import { Badge, Table } from "flowbite-react";
import { formatToReal } from "../../../utils/utils";

const HistoricoConsultas = ({
  historicoConsultas,
  quantidadeTotal,
  periodoInicial,
  periodoFinal,
}) => {
  return (
    <>
      <div className="flex gap-6">
        {quantidadeTotal && (
          <div>
            <span className="font-semibold">Quantidade Total: </span>
            {quantidadeTotal}
          </div>
        )}
        {periodoInicial && periodoFinal && (
          <div>
            <span className="font-semibold">Período: </span>
            {periodoInicial} até {periodoFinal}
          </div>
        )}
      </div>
      <div className="overflow-x-auto border-gray-100 border rounded-lg mt-3">
        <Table striped>
          <Table.Head>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Data
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Empresa
            </Table.HeadCell>
          </Table.Head>

          {historicoConsultas?.length > 0 && (
            <Table.Body className="divide-y">
              {historicoConsultas?.map((consulta, index) => (
                <Table.Row
                  key={index}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                >
                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center text-black py-1">
                    {consulta?.data || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center text-black py-1">
                    {consulta?.empresa || "-"}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </div>
    </>
  );
};

export default HistoricoConsultas;
