import { Badge, Table } from "flowbite-react";
import { formatToReal } from "../../../utils/utils";

const PendenciasFinanceiras = ({
  pendenciasFinanceiras,
  quantidadeTotal,
  dataMaiorPendencia,
  valorTotal,
}) => {
  return (
    <>
      <div className="flex gap-6">
        {quantidadeTotal && (
          <div className="flex gap-2 items-center">
            <span className="font-semibold text-red-700">Quantidade: </span>
            <Badge color="failure" className="w-fit">
              {quantidadeTotal}
            </Badge>
          </div>
        )}

        {dataMaiorPendencia && (
          <div className="flex gap-2 items-center">
            <span className="font-semibold text-red-700">
              Data da maior pendência:{" "}
            </span>
            <Badge color="failure" className="w-fit">
              {dataMaiorPendencia}
            </Badge>
          </div>
        )}

        {valorTotal && (
          <div className="flex gap-2 items-center">
            <span className="font-semibold text-red-700">Valor Total: </span>
            <Badge color="failure" className="w-fit">
              {formatToReal(valorTotal)}
            </Badge>
          </div>
        )}
      </div>
      <div className="overflow-x-auto border-gray-100 border rounded-lg mt-3">
        <Table striped>
          <Table.Head>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Data
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Informante
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Valor
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Cidade:
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Tipo:
            </Table.HeadCell>
          </Table.Head>
          {pendenciasFinanceiras?.length > 0 && (
            <Table.Body className="divide-y">
              {pendenciasFinanceiras?.map((pendencia, index) => (
                <Table.Row
                  key={index}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                >
                  <Table.Cell className="font-medium text-black dark:text-white text-center py-1">
                    {pendencia?.data || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center py-1 text-black">
                    {pendencia?.informante || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center py-1 text-black">
                    {formatToReal(pendencia?.valor?.replace(",", ".")) || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center py-1 text-black">
                    {pendencia?.cidade || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center py-1 text-black">
                    {pendencia?.tipoPendencia || "-"}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </div>
    </>
  );
};

export default PendenciasFinanceiras;
