import React, { useState } from "react";
import { Badge, Card, Table } from "flowbite-react";
import DocDefineRisco from "../documentations/DocDefineRisco";
import DocAcertaEssencial from "../documentations/DocAcertaEssencial";
import { API } from "../../config/apiConfig";
import axios from "../../config/axiosInstance";
import { useQuery } from "react-query";
import DocCompletaPlus from "../documentations/DocCompletaPlus";
import DocSCRBacen from "../documentations/DocSCRBacen";

const DocsTable = () => {
  const [selectedQuery, setSelectedQuery] = useState(0);
  const listForms = [];

  const {
    isLoading,
    error: errorToken,
    data: token,
  } = useQuery(["token"], async () => {
    const data = await axios.get(`${API.GET_TOKEN}`);

    return data.data.token;
  });

  return (
    <div className="flex flex-col lg:flex-row gap-2">
      <div className="p-4 flex flex-col gap-2 w-full md:w-1/4">
        <h1 className="title-syncx mb-2">Consultas</h1>
        <Card
          className={`card-query ${selectedQuery === 0 && "active"}`}
          onClick={() => setSelectedQuery(0)}
        >
          Positivo Plus CNPJ
        </Card>
        <Card
          className={`card-query ${selectedQuery === 1 && "active"}`}
          onClick={() => setSelectedQuery(1)}
        >
          Positivo Plus CPF
        </Card>
        <Card
          className={`card-query ${selectedQuery === 2 && "active"}`}
          onClick={() => setSelectedQuery(2)}
        >
          Completa Plus
        </Card>
        <Card
          className={`card-query ${selectedQuery === 3 && "active"}`}
          onClick={() => setSelectedQuery(3)}
        >
          SCR Bacen + Score
        </Card>
      </div>
      <div className="w-full md:w-3/4">
        {selectedQuery === 0 && <DocDefineRisco token={token} />}
        {selectedQuery === 1 && <DocAcertaEssencial token={token} />}
        {selectedQuery === 2 && <DocCompletaPlus token={token} />}
        {selectedQuery === 3 && <DocSCRBacen token={token} />}
      </div>
    </div>
  );
};

export default DocsTable;
