import { Alert, Card, Table } from "flowbite-react";
import React, { useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { isValidCNPJ, isValidCPF } from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import CardQueryDescription from "../common/CardQueryDescription";
import QueryInput from "../inputs/QueryInput";
import TitleStripe from "../common/TitleStripe";
import QueryLoading from "../common/QueryLoading";
import FooterQuery from "./FooterQuery";

const ProtestoNacionalQuery = ({ query, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);

  const fetchInfo = async (raw) => {
    return await axios(
      `${QUERIES.GET_PROTESTO_NACIONAL}/${info.replace(/\D/g, "")}?audit=true`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;
    let type = "F";

    if (unmaskedValue.length <= 11) {
      if (!isValidCPF(unmaskedValue)) {
        setError("CPF inválido!");
        isValid = false;
      }
    } else {
      if (!isValidCNPJ(unmaskedValue)) {
        setError("CNPJ inválido!");
        isValid = false;
      }
      type = "J";
    }

    if (isValid) {
      try {
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data } = await fetchInfo();
        setData(data.data);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={["Protestos Nacionais"]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 mt-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex gap-2 items-end">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CPF/CNPJ"
            />
          </div>
        </form>
      </CardQueryDescription>
      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <div className={`${data || isLoading ? "block" : "hidden"}`}>
          {!isLoading ? (
            <Card>
              <HeaderQuery title={query?.name} />

              <div className="title-syncx mt-5">Quadro de avisos</div>
              <div>
                <Card
                  className={`${
                    +data?.CREDCADASTRAL?.PROTESTO_SINTETICO
                      ?.QUANTIDADE_OCORRENCIA === 0
                      ? "bg-green-500"
                      : "bg-red-500"
                  } mb-3`}
                >
                  <div className="flex gap-2 items-center text-white font-semibold">
                    <div>
                      <FaRegCheckCircle fontSize={20} />
                    </div>
                    <div>
                      Protestos:{" "}
                      {+data?.CREDCADASTRAL?.PROTESTO_SINTETICO
                        ?.QUANTIDADE_OCORRENCIA === 0
                        ? "Nada consta"
                        : `Possui ${data?.CREDCADASTRAL?.PROTESTO_SINTETICO?.QUANTIDADE_OCORRENCIA} protestos`}
                    </div>
                  </div>
                </Card>
              </div>
              <div>
                <TitleStripe className="mb-5">
                  Protestos direto do cartório
                </TitleStripe>
                {!isLoading ? (
                  <>
                    {+data?.CREDCADASTRAL?.PROTESTO_SINTETICO
                      ?.QUANTIDADE_OCORRENCIA > 0 ? (
                      <>
                        <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                          <Table striped>
                            <Table.Head>
                              <Table.HeadCell className="bg-red-300">
                                Cartório
                              </Table.HeadCell>
                              <Table.HeadCell className="bg-red-300">
                                Credor
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Telefone
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Valor
                              </Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y text-sm">
                              {data?.CREDCADASTRAL?.PROTESTO_SINTETICO?.OCORRENCIAS?.map(
                                (protesto, index) => (
                                  <Table.Row
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    key={index}
                                  >
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                      {protesto?.CARTORIO}
                                    </Table.Cell>
                                    <Table.Cell>{protesto?.CREDOR}</Table.Cell>
                                    <Table.Cell className="text-center">
                                      {protesto?.TELEFONE}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      R$ {protesto?.VALOR}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </Table.Body>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <Card className={`bg-green-200  mb-3`}>
                        <div className="flex gap-2 items-center text-gray-600 font-semibold">
                          <div>
                            <FaRegCheckCircle fontSize={20} />
                          </div>
                          <div>Nada consta</div>
                        </div>
                      </Card>
                    )}
                  </>
                ) : (
                  <div role="status" className="w-full animate-pulse">
                    <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>{" "}
                  </div>
                )}
              </div>

              <FooterQuery hasHeader={true} />
            </Card>
          ) : (
            <QueryLoading />
          )}
        </div>
      )}
    </>
  );
};

export default ProtestoNacionalQuery;
