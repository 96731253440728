import { Alert } from "flowbite-react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FiAlertOctagon } from "react-icons/fi";

const AvisoCard = ({
  hasRestrictions,
  title,
  messageError,
  messageSuccess = "Nada consta",
}) => {
  return (
    <>
      {!hasRestrictions ? (
        <div className="flex relative flex-col bg-primary rounded-md font-semibold pt-1 pb-6 px-2 text-white shadow-md">
          <div className="text-left text-sm">{title}</div>
          <div className="text-center font-semibold bg-green-100 w-fit text-green-800 px-2 rounded-md mx-auto mt-4">
            Nada consta
          </div>
        </div>
      ) : (
        <div className="flex relative flex-col bg-primary rounded-md font-semibold pt-1 pb-6 px-2 text-white shadow-md">
          <div className="text-left text-sm">{title}</div>
          <div className="text-center font-semibold bg-red-100 w-fit text-red-800 px-2 rounded-md mx-auto mt-4">
            {messageError}
          </div>
        </div>
      )}
    </>
  );
};

export default AvisoCard;
