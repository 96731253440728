import React, { useEffect, useState } from "react";
import { componentsMap } from "../components/common/ComponentMap";

const PDFQueryPage = () => {
  const [data, setData] = useState(null);
  const [componentName, setComponentName] = useState(null);
  const [hasHeaderPDF, setHasHeaderPDF] = useState("true");

  useEffect(() => {
    const data = localStorage.getItem("data");
    const componentName = localStorage.getItem("component");
    const hasHeaderPDF = localStorage.getItem("hasHeaderPDF");

    if (data) {
      try {
        const parsedData = JSON.parse(data);
        setData(parsedData);
        setComponentName(componentName);
        setHasHeaderPDF(hasHeaderPDF);
      } catch (error) {
        console.error("Erro ao analisar JSON:", error);
      }
    } else {
      console.log("Nenhum dado encontrado no localStorage.");
    }
  }, []);

  const DynamicComponent = componentsMap[componentName] || null;

  return (
    <div className="overflow-hidden">
      {DynamicComponent ? (
        <DynamicComponent
          dataFromLocalStorage={data}
          hasHeaderPDF={hasHeaderPDF}
        />
      ) : (
        <p>Nenhum componente para renderizar</p>
      )}
    </div>
  );
};

export default PDFQueryPage;
