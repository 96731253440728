import { Alert, Badge, Button, Card } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import axios from "../../../config/axiosInstance";
import { QUERIES_VEICULAR } from "../../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "../HeaderQuery";
import usePDF from "../../../hooks/usePDF";
import TitleStripe from "../../common/TitleStripe";
import CardQueryDescription from "../../common/CardQueryDescription";
import QueryInput from "../../inputs/QueryInput";
import SliderToPreview from "../../common/SliderToPreview";
import Loader from "../../common/Loader";
import { RiAuctionLine } from "react-icons/ri";
import { getImageURLScore } from "../../../utils/utils";
import FooterQuery from "../FooterQuery";

const LeilaoPositivoQuery = ({
  query,
  selectedPrice,
  dataFromLocalStorage = null,
  hasHeaderPDF = "true",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    data
  );

  useEffect(() => {
    if (dataFromLocalStorage) {
      setData(dataFromLocalStorage);
      setDocument(dataFromLocalStorage?.resumoRetorno?.document);
    }
  }, []);

  const fetchInfo = async (raw) => {
    return await axios(`${QUERIES_VEICULAR.GET_LEILAO_POSITIVO}/${info}`);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);
    let isValid = true;

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados.data);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Resumo do veículo",
          "informações do veículo",
          "Dados de Leilão",
        ]}
        price={selectedPrice}
        imageBanner="/assets/bg_banner_car.jpg"
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-end">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="PLACA"
            />
            <div>
              {!errorQuery && data && (
                <Button
                  className={`w-fit shadow-lg secondary ${
                    data && !isLoading ? "block" : "hidden"
                  }`}
                  isProcessing={pdfURL === ""}
                  disabled={pdfURL === ""}
                  onClick={() => handleClickDownloadPDF()}
                >
                  Salvar como PDF
                </Button>
              )}
            </div>
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`overflow-x-scroll ${
              data || isLoading ? "block" : "hidden"
            }`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="relative overflow-hidden p-5 card-pdf">
                  <HeaderQuery title={query?.name} type="vehicle" />

                  <TitleStripe>Quadro de avisos</TitleStripe>
                  <div>
                    <>
                      {data?.resposta?.leilaoLocal?.STATUS == 404 &&
                      data?.resposta?.baseLeilao?.RESPOSTA?.LEILAOXML
                        ?.situacao ? (
                        <div className="flex bg-green-300 px-2 py-4 rounded-md gap-4 shadow items-center">
                          <img
                            src={`data:image/png;base64, ${data?.resposta?.automotivaAgregado?.RESPOSTA?.RETORNO?.imagem}`}
                            className="w-32"
                          />
                          <div className="text-green-800 flex gap-2 text-lg">
                            <RiAuctionLine fontSize={30} />
                            <div className="text-green-800 font-semibold">
                              Este veículo não tem passagem por leilão.
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex bg-red-200 px-2 py-4 rounded-md gap-4 shadow items-center">
                          <img
                            src={`data:image/png;base64, ${data?.resposta?.automotivaAgregado?.RESPOSTA?.RETORNO?.imagem}`}
                            className="w-32"
                          />
                          <div className="text-red-800 flex gap-2 text-lg">
                            <RiAuctionLine fontSize={30} />
                            <div className="font-semibold">
                              Este veículo tem passagem por leilão.
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  </div>

                  <TitleStripe>
                    <div className="flex gap-2 items-center">
                      Resumo do veículo{" "}
                      <img
                        className="object-contain w-10"
                        src={`${data?.resposta?.automotivaAgregado?.RESPOSTA?.RETORNO?.logo}`}
                      />
                    </div>
                  </TitleStripe>
                  <div className="flex gap-20">
                    <div className="text-sm flex-1">
                      <div className="mb-3">
                        <span className="font-semibold">Tipo do Veículo: </span>
                        {data?.resposta?.automotivaAgregado?.RESPOSTA?.RETORNO
                          ?.TIPOVEICULO || "Sem info."}
                      </div>
                      <div className="mb-3 flex items-center gap-2">
                        <div>
                          <span className="font-semibold">Marca/Modelo: </span>
                          {data?.resposta?.automotivaAgregado?.RESPOSTA?.RETORNO
                            ?.MARCAMODELO || "Sem info."}
                        </div>
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Ano Fabr./Modelo:{" "}
                        </span>
                        {`${data?.resposta?.automotivaAgregado?.RESPOSTA?.RETORNO?.ANOFABRICACAO}/${data?.resposta?.automotivaAgregado?.RESPOSTA?.RETORNO?.ANOMODELO} ` ||
                          "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Cor: </span>
                        {data?.resposta?.automotivaAgregado?.RESPOSTA?.RETORNO
                          ?.CORVEICULO || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Combustível: </span>
                        {data?.resposta?.automotivaAgregado?.RESPOSTA?.RETORNO
                          ?.COMBUSTIVEL || "Indisponível"}
                      </div>
                    </div>
                    <div className="text-sm flex-1">
                      <div className="mb-3">
                        <span className="font-semibold">Placa: </span>
                        {document}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Cidade: </span>
                        {data?.resposta?.automotivaAgregado?.RESPOSTA?.RETORNO
                          ?.MUNICIPIO || "Indisponível"}
                        /
                        {
                          data?.resposta?.automotivaAgregado?.RESPOSTA?.RETORNO
                            ?.UF
                        }
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Renavam: </span>
                        {data?.resposta?.automotivaAgregado?.RESPOSTA?.RETORNO
                          ?.RENAVAM || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Chassi: </span>
                        {data?.resposta?.automotivaAgregado?.RESPOSTA?.RETORNO
                          ?.CHASSI || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Data de Atualização:{" "}
                        </span>
                        {data?.resposta?.automotivaAgregado?.RESPOSTA?.RETORNO
                          ?.DATAULTIMAATUALIZACAO || "Indisponível"}
                      </div>
                    </div>
                  </div>

                  {!data?.resposta?.leilaoLocal?.STATUS == 404 && (
                    <div>
                      <TitleStripe>Resumo do leilão</TitleStripe>
                      <div className="mt-3 bg-red-100 p-2">
                        <div className="mb-3">
                          <span className="font-semibold">Modelo: </span>
                          {`${data?.resposta?.leilaoLocal?.modelo}` ||
                            "Indisponível"}
                        </div>
                        <div className="mb-3">
                          <span className="font-semibold">Marca: </span>
                          {`${data?.resposta?.leilaoLocal?.marca}` ||
                            "Indisponível"}
                        </div>
                        <div className="mb-3">
                          <span className="font-semibold">
                            Ano Fabr./Modelo:{" "}
                          </span>
                          {`${data?.resposta?.leilaoLocal?.anoFabricacao}/${data?.resposta?.leilaoLocal?.anoModelo}` ||
                            "Indisponível"}
                        </div>
                        <div className="mb-3">
                          <span className="font-semibold">Leiloeiro: </span>
                          {`${data?.resposta?.leilaoLocal?.anoFabricacao}/${data?.resposta?.leilaoLocal?.leiloeiro}` ||
                            "Indisponível"}
                        </div>
                        <div className="mb-3">
                          <span className="font-semibold">Comitente: </span>
                          {`${data?.resposta?.leilaoLocal?.anoFabricacao}/${data?.resposta?.leilaoLocal?.comitente}` ||
                            "Indisponível"}
                        </div>
                        <div>
                          <span className="font-semibold">Imagens: </span>
                        </div>
                      </div>
                    </div>
                  )}

                  {data?.resposta?.baseLeilao?.RESPOSTA?.LEILAOXML_1 && (
                    <div>
                      <TitleStripe>Resumo do leilão</TitleStripe>
                      <div className="mt-5 flex gap-16 items-center">
                        <div className="w-2/6 max-w-2/6">
                          <img
                            src={getImageURLScore(
                              data?.resposta?.baseLeilao?.RESPOSTA?.LEILAOXML_1
                                ?.SCORE
                            )}
                          />
                          <Badge className="w-fit mx-auto mt-2 text-lg">
                            {
                              data?.resposta?.baseLeilao?.RESPOSTA?.LEILAOXML_1
                                ?.SCORE
                            }
                          </Badge>
                        </div>
                        <div className="text-sm">
                          <div className="mb-3">
                            <span className="font-semibold">Modelo: </span>
                            {`${data?.resposta?.baseLeilao?.RESPOSTA?.LEILAOXML_1?.MODELO}` ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Marca: </span>
                            {`${data?.resposta?.baseLeilao?.RESPOSTA?.LEILAOXML_1?.MARCA}` ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Ano Fabr./Modelo:{" "}
                            </span>
                            {`${data?.resposta?.baseLeilao?.RESPOSTA?.LEILAOXML_1?.ANO_FABRICACAO}/${data?.resposta?.baseLeilao?.RESPOSTA?.LEILAOXML_1?.ANO_MODELO}` ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Lote: </span>
                            {`${data?.resposta?.baseLeilao?.RESPOSTA?.LEILAOXML_1?.LOTE}` ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Leiloeiro: </span>
                            {`${data?.resposta?.baseLeilao?.RESPOSTA?.LEILAOXML_1?.LEILOEIRO}` ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Comitente: </span>
                            {`${data?.resposta?.baseLeilao?.RESPOSTA?.LEILAOXML_1?.COMITENTE}` ||
                              "Indisponível"}
                          </div>
                        </div>
                        <div className="text-sm">
                          <div className="mb-3">
                            <span className="font-semibold">Modelo: </span>
                            {`${data?.resposta?.baseLeilao?.RESPOSTA?.LEILAOXML_1?.MODELO}` ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Marca: </span>
                            {`${data?.resposta?.baseLeilao?.RESPOSTA?.LEILAOXML_1?.MARCA}` ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Ano Fabr./Modelo:{" "}
                            </span>
                            {`${data?.resposta?.baseLeilao?.RESPOSTA?.LEILAOXML_1?.ANO_FABRICACAO}/${data?.resposta?.baseLeilao?.RESPOSTA?.LEILAOXML_1?.ANO_MODELO}` ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Lote: </span>
                            {`${data?.resposta?.baseLeilao?.RESPOSTA?.LEILAOXML_1?.LOTE}` ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Leiloeiro: </span>
                            {`${data?.resposta?.baseLeilao?.RESPOSTA?.LEILAOXML_1?.LEILOEIRO}` ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Comitente: </span>
                            {`${data?.resposta?.baseLeilao?.RESPOSTA?.LEILAOXML_1?.COMITENTE}` ||
                              "Indisponível"}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div>
                    <TitleStripe>Informações do veículo</TitleStripe>
                    <div className="flex gap-10 mt-4 p-2 rounded bg-gray-100">
                      <div className="text-sm flex-1">
                        <div className="">
                          <div className="mb-3">
                            <span className="font-semibold">
                              Marca/Modelo:{" "}
                            </span>
                            {data?.resposta?.automotivaAgregado?.RESPOSTA
                              ?.RETORNO?.MARCA_MODELO || "Sem info."}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Ano Fabr./Modelo:{" "}
                            </span>
                            {`${data?.resposta?.automotivaAgregado?.RESPOSTA?.RETORNO?.ANOFABRICACAO}/${data?.resposta?.automotivaAgregado?.RESPOSTA?.RETORNO?.ANOMODELO}` ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo de veículo:{" "}
                            </span>
                            {data?.resposta?.automotivaAgregado?.RESPOSTA
                              ?.RETORNO?.TIPOVEICULO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Placa: </span>
                            {document || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">RENAVAM: </span>
                            {data?.resposta?.automotivaAgregado?.RESPOSTA
                              ?.RETORNO?.RENAVAM || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Chassi: </span>
                            {data?.resposta?.automotivaAgregado?.RESPOSTA
                              ?.RETORNO?.CHASSI || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Situação: </span>
                            {data?.resposta?.automotivaAgregado?.RESPOSTA
                              ?.RETORNO?.SITUACAOCHASSI || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Capacidade Carga:{" "}
                            </span>
                            {data?.resposta?.automotivaAgregado?.RESPOSTA
                              ?.RETORNO?.CAPACIDADECARGA || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Cilindrada: </span>
                            {data?.resposta?.automotivaAgregado?.RESPOSTA
                              ?.RETORNO?.CILINDRADAS || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Combustível: </span>
                            {data?.resposta?.automotivaAgregado?.RESPOSTA
                              ?.RETORNO?.COMBUSTIVEL || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Cor: </span>
                            {data?.resposta?.automotivaAgregado?.RESPOSTA
                              ?.RETORNO?.CORVEICULO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Procedência: </span>
                            {data?.resposta?.automotivaAgregado?.RESPOSTA
                              ?.RETORNO?.PROCEDENCIA || "Indisponível"}
                          </div>
                        </div>
                      </div>
                      <div className="text-sm flex-1">
                        <div className="">
                          <div className="mb-3">
                            <span className="font-semibold">Espécie: </span>
                            {data?.resposta?.automotivaAgregado?.RESPOSTA
                              ?.RETORNO?.ESPECIEVEICULO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Município: </span>
                            {data?.resposta?.automotivaAgregado?.RESPOSTA
                              ?.RETORNO?.MUNICIPIO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">UF: </span>
                            {data?.resposta?.automotivaAgregado?.RESPOSTA
                              ?.RETORNO?.UF || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Nº Carroceria:{" "}
                            </span>
                            {data?.resposta?.automotivaAgregado?.RESPOSTA
                              ?.RETORNO?.CARROCERIA || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Nº DI: </span>
                            {data?.resposta?.automotivaAgregado?.RESPOSTA
                              ?.RETORNO?.NUMERO_DI || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data registro DI:{" "}
                            </span>
                            {data?.resposta?.automotivaAgregado?.RESPOSTA
                              ?.RETORNO?.DTREGISTRO_DI || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Qtd. Eixos: </span>
                            {data?.resposta?.automotivaAgregado?.RESPOSTA
                              ?.RETORNO?.NUMEROEIXOS || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Nº Motor: </span>
                            {data?.resposta?.automotivaAgregado?.RESPOSTA
                              ?.RETORNO?.MOTOR || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Potência: </span>
                            {data?.resposta?.automotivaAgregado?.RESPOSTA
                              ?.RETORNO?.POTENCIA || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data Atualização:{" "}
                            </span>
                            {data?.resposta?.automotivaAgregado?.RESPOSTA
                              ?.RETORNO?.DATAULTIMAATUALIZACAO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Documento Faturado
                            </span>
                            {data?.resposta?.automotivaAgregado?.RESPOSTA
                              ?.RETORNO?.DOCFATURADO || "Indisponível"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <FooterQuery hasHeader={hasHeaderPDF} />
                </Card>
              </>
            ) : (
              <Loader
                words={[
                  "Resumo do veículo",
                  "Informações do veículo",
                  "Dados de Leilão",
                ]}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default LeilaoPositivoQuery;
