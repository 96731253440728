import moment from "moment";
import React, { useState } from "react";
import FilterToolbar from "../inputs/FilterToolbar";
import { Badge, Button, Pagination, Table } from "flowbite-react";
import { LuCalendarClock } from "react-icons/lu";
import { FaArrowsLeftRight } from "react-icons/fa6";
import axios from "../../config/axiosInstance";
import { useQuery } from "react-query";
import { AUDITS } from "../../config/apiConfig";
import EmptyTable from "./EmptyTable";
import TableLoading from "../TableLoading";

const AuditsTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const onPageChange = (page) => setCurrentPage(page);
  const [startDatePeriod, setStartDatePeriod] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDatePeriod, setEndDatePeriod] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);

  const {
    isLoadingListAudits,
    error: errorListAudits,
    data: listAudits,
  } = useQuery(
    ["listAudits", currentPage, startDatePeriod, endDatePeriod],
    async () => {
      const { data } = await axios.get(
        `${AUDITS.GET_AUDITS}?page=${currentPage}&startDate=${startDatePeriod}&endDate=${endDatePeriod}`
      );

      setTotalPages(data.data.totalPages || 1);
      setTotalCount(data.data.totalCount || 0);

      return data.data.data;
    }
  );

  const onClickSearchPeriod = () => {
    setStartDatePeriod(startDate);
    setEndDatePeriod(endDate);
  };

  return (
    <>
      <FilterToolbar
        title="Selecionar Período:"
        icon={<LuCalendarClock className="text-lg" />}
        isVisible={isVisibleFilter}
        setIsVisible={setIsVisibleFilter}
        style={{ background: "var(--bg-black)" }}
      >
        <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
          <input
            type="date"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
              if (moment(e.target.value).isAfter(moment(endDate))) {
                setEndDate(e.target.value);
              }
            }}
            className="w-full md:w-fit block py-3 md:py-2 px-2 rounded-md text-sm text-gray-600 bg-gray-100 border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          />
          <div className="text-sm text-white">Até</div>
          <input
            type="date"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
            min={startDatePeriod}
            className="w-full md:w-fit block py-3 md:py-2 px-2 rounded-md text-sm text-gray-600 bg-gray-100 border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          />
        </div>
        <Button onClick={onClickSearchPeriod} className="primary">
          Buscar
        </Button>
      </FilterToolbar>
      <div className="mt-10 text-sm md:text-md italic">
        Informações baseadas no período selecionado{" "}
        <Badge className="w-fit inline" color="info">
          {moment(startDatePeriod).format("DD/MM/YYYY")}
        </Badge>{" "}
        -{" "}
        <Badge className="w-fit inline" color="info">
          {moment(endDatePeriod).format("DD/MM/YYYY")}
        </Badge>
      </div>

      {listAudits?.length > 0 || isLoadingListAudits ? (
        <>
          {" "}
          <small
            className="gap-2 items-center mt-5 mb-1 flex md:hidden justify-center"
            style={{ fontSize: 10 }}
          >
            <span>
              Deslize para o lado para visualizar todas as informações
            </span>{" "}
            <FaArrowsLeftRight />
          </small>
          <div className="overflow-x-auto shadow-md rounded-lg mt-0 md:mt-5">
            <Table striped>
              <Table.Head>
                <Table.HeadCell className="text-center bg-gray-300">
                  IP
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Ação
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Data/Hora
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Usuário
                </Table.HeadCell>
              </Table.Head>

              <Table.Body className="divide-y">
                {!isLoadingListAudits ? (
                  <>
                    {listAudits?.length > 0 && (
                      <>
                        {listAudits.map((audit, index) => (
                          <Table.Row
                            key={index}
                            className="bg-white dark:border-gray-700 dark:bg-gray-800"
                          >
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                              {audit.ip}
                            </Table.Cell>
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2 ">
                              {audit.action}
                            </Table.Cell>
                            <Table.Cell className="text-center py-2">
                              {moment(audit.createdAt).format(
                                "DD/MM/YYYY - HH:mm"
                              )}
                            </Table.Cell>
                            <Table.Cell className="text-center py-2">
                              <div className="flex gap-1 justify-center items-center flex-wrap">
                                {audit?.User?.name && (
                                  <Badge>{audit?.User?.name}</Badge>
                                )}
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  <TableLoading cols={4} />
                )}
              </Table.Body>
            </Table>
          </div>
          {totalCount > 0 && (
            <div className="mt-3 text-center text-sm">
              Foram encontrados {totalCount} registros no total
            </div>
          )}
          {totalPages > 1 && (
            <div className="flex overflow-x-auto justify-center mt-3">
              <Pagination
                layout="pagination"
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
                previousLabel=""
                nextLabel=""
                showIcons
              />
            </div>
          )}
        </>
      ) : (
        <EmptyTable icon="search_off">
          Nenhuma ação realizada no sistema neste período
        </EmptyTable>
      )}
    </>
  );
};

export default AuditsTable;
