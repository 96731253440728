import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Checkbox,
  Label,
  Pagination,
  Spinner,
  Table,
} from "flowbite-react";
import { FaArrowsLeftRight } from "react-icons/fa6";
import axios from "../../config/axiosInstance";
import { useQuery } from "react-query";
import { ADMIN } from "../../config/apiConfig";
import { formatToReal } from "../../utils/utils";
import AutoCompleteInput from "../inputs/Autocomplete";
import EmptyTable from "./EmptyTable";
import { MdOutlineAttachMoney } from "react-icons/md";
import { BsFillBarChartFill } from "react-icons/bs";
import DrawerLoading from "../DrawerLoading";

const AdminPaymentsTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalPriceSum, setTotalPriceSum] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const onPageChange = (page) => setCurrentPage(page);
  const [selectedCustomer, setSelectedCustomer] = useState({
    label: "Todos os clientes",
    value: 0,
  });
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedTypePayments, setSelectedTypePayments] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const {
    isLoading: isLoadingCustomers,
    error: errorListCustomers,
    data: listCustomers,
  } = useQuery(["listCustomers"], async () => {
    try {
      const { data } = await axios.get(`${ADMIN.GET_CUSTOMERS}?itemsPerPage=-1`);

      if (!data?.data?.data) {
        throw new Error("Erro ao buscar clientes");
      }

      let customers = data.data.data.map((customer) => ({
        label:
          customer.typeCustomer === "CPF"
            ? customer.name
            : customer.socialReason,
        value: customer.id,
      }));

      customers = [{ label: "Todos os clientes", value: 0 }, ...customers];

      return customers;
    } catch (error) {
      throw new Error(`Erro ao buscar clientes: ${error.message}`);
    }
  });

  const {
    isLoading: isLoadingInvoices,
    error: errorInvoices,
    data: invoices,
  } = useQuery(
    [
      "invoices",
      startDate,
      endDate,
      selectedStatuses,
      selectedTypePayments,
      currentPage,
      selectedCustomer,
    ],
    async () => {
      const { data } = await axios.get(
        `${ADMIN.GET_PAYMENTS}?startDate=${startDate}&endDate=${endDate}&status=${selectedStatuses}&typePayment=${selectedTypePayments}&page=${currentPage}&companyId=${selectedCustomer?.value}`
      );

      setTotalPages(data.data.totalPages);
      setTotalCount(data.data.totalCount);
      setTotalPriceSum(data.data.totalPrice);

      return data.data.data;
    }
  );

  const handleCheckboxChange = (status, setState) => {
    setState((prev) =>
      prev.includes(status)
        ? prev.filter((item) => item !== status)
        : [...prev, status]
    );
  };

  return (
    <div className="flex md:flex-row flex-col gap-4">
      <div className="w-full md:w-80 bg-white rounded-md shadow-md p-4 relative">
        {isLoadingCustomers && <DrawerLoading />}
        <div className="title-syncx">Filtro avançado</div>
        <div className="mt-6">
          <AutoCompleteInput
            options={listCustomers}
            label="Cliente"
            onChange={(customer) => setSelectedCustomer(customer)}
            value={selectedCustomer?.value}
            isLoading={isLoadingCustomers}
          />
          {errorListCustomers && (
            <Alert color="failure">Ocorreu um erro ao buscar clientes</Alert>
          )}
        </div>

        <div className="font-semibold mt-4 text-sm mb-2">Período</div>
        <div className="flex items-center gap-1">
          <input
            type="date"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
              if (moment(e.target.value).isAfter(moment(endDate))) {
                setEndDate(e.target.value);
              }
            }}
            className="w-full block py-3 md:py-2 px-2 rounded-md text-xs text-gray-600 bg-gray-100 border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          />
          <div className="text-xs">Até</div>
          <input
            type="date"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
            min={startDate}
            className="w-full block py-3 md:py-2 px-2 rounded-md text-xs text-gray-600 bg-gray-100 border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          />
        </div>
        <div className="mt-4 text-sm">
          <div className="font-semibold">Status</div>
          <ul className="mt-2 flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <Checkbox
                id="pendente"
                checked={selectedStatuses.includes(0)}
                onChange={() => handleCheckboxChange(0, setSelectedStatuses)}
              />
              <Label htmlFor="pendente" className="flex">
                Pendente
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                id="pago"
                checked={selectedStatuses.includes(1)} // 0 = Falha
                onChange={() => handleCheckboxChange(1, setSelectedStatuses)}
              />
              <Label htmlFor="pago" className="flex">
                Pago
              </Label>
            </div>
          </ul>
        </div>
        <div className="mt-4 text-sm">
          <div className="font-semibold">Tipo de pagamento</div>
          <ul className="mt-2 flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <Checkbox
                id="boleto"
                checked={selectedTypePayments.includes("BOLETO")}
                onChange={() =>
                  handleCheckboxChange("BOLETO", setSelectedTypePayments)
                }
              />
              <Label htmlFor="boleto" className="flex">
                Boleto
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                id="pix"
                checked={selectedTypePayments.includes("PIX")} // 0 = Falha
                onChange={() =>
                  handleCheckboxChange("PIX", setSelectedTypePayments)
                }
              />
              <Label htmlFor="accept" className="flex">
                Pix
              </Label>
            </div>
          </ul>
        </div>
      </div>
      <div className="flex-1">
        <div className="title-syncx mt-3 mb-4">Resultado da busca</div>
        <div className="overflow-x-scroll">
          <div className="flex gap-3 pb-3" style={{ minWidth: "500px" }}>
            <Card
              className="w-full bg-gray-700 relative"
              style={{ minWidth: "150px" }}
            >
              <div className="text-sm md:text-md lg:text-lg text-white">
                Valor Total:
              </div>
              <div className="text-2xl text-white font-semibold flex gap-2 items-center">
                {!isLoadingInvoices ? (
                  `${formatToReal(totalPriceSum)}`
                ) : (
                  <div role="status" className="max-w-sm animate-pulse inline">
                    <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                  </div>
                )}
              </div>
              <MdOutlineAttachMoney className="absolute bottom-0 right-0 text-white text-9xl opacity-10" />
            </Card>
            <Card className="w-full bg-gray-700 relative overflow-hidden">
              <div className="text-sm md:text-md lg:text-lg text-white">
                Total de pagamentos:
              </div>
              <div className="text-2xl text-white font-semibold">
                {!isLoadingInvoices ? (
                  `${totalCount}`
                ) : (
                  <div role="status" className="max-w-sm animate-pulse inline">
                    <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                  </div>
                )}
              </div>
              <BsFillBarChartFill className="absolute bottom-0 right-2 text-white text-9xl opacity-10" />
            </Card>
          </div>
        </div>

        <small
          className="gap-2 items-center mt-5 mb-1 flex md:hidden justify-center"
          style={{ fontSize: 10 }}
        >
          <span>Deslize para o lado para visualizar todas as informações</span>{" "}
          <FaArrowsLeftRight />
        </small>
        {invoices?.length > 0 || isLoadingInvoices ? (
          <div className="overflow-x-auto shadow-md rounded-lg mt-0 md:mt-5">
            <Table striped style={{ fontSize: "13px" }}>
              <Table.Head>
                <Table.HeadCell className="text-center bg-gray-300">
                  Data de criação
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Data de pgto.
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Valor
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Tipo
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Status
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Ações
                </Table.HeadCell>
              </Table.Head>

              <Table.Body className="divide-y">
                {!isLoadingInvoices ? (
                  <>
                    {invoices?.length > 0 && (
                      <>
                        {invoices.map((invoice, index) => (
                          <Table.Row
                            key={index}
                            className="bg-white dark:border-gray-700 dark:bg-gray-800"
                          >
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-3">
                              {moment(invoice.createdAt).format("DD/MM/yyyy")}
                            </Table.Cell>
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                              {invoice.dueDate
                                ? moment(invoice.dueDate).format("DD/MM/yyyy")
                                : "-"}
                            </Table.Cell>
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                              {formatToReal(invoice?.price)}
                            </Table.Cell>
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                              {invoice.type}
                            </Table.Cell>
                            <Table.Cell className="text-center py-2">
                              {invoice.isPaid ? (
                                <Badge
                                  color="success"
                                  className="w-fit mx-auto text-xs"
                                >
                                  Pago
                                </Badge>
                              ) : (
                                <Badge
                                  color="warning"
                                  className="w-fit mx-auto text-xs"
                                >
                                  Pendente
                                </Badge>
                              )}
                            </Table.Cell>
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                              {invoice.paymentLink ? (
                                <a
                                  className="bg-primary rounded text-white p-2"
                                  href={invoice.paymentLink}
                                  target="_blank"
                                >
                                  Link de pagamento
                                </a>
                              ) : (
                                <div>-</div>
                              )}
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {Array.from({ length: 12 }).map((_, index) => (
                      <Table.Row
                        key={index}
                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                      >
                        <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                          <div
                            role="status"
                            className="max-w-sm animate-pulse inline"
                          >
                            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mx-auto"></div>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                          <div
                            role="status"
                            className="max-w-sm animate-pulse inline"
                          >
                            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mx-auto"></div>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                          <div
                            role="status"
                            className="max-w-sm animate-pulse inline"
                          >
                            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mx-auto"></div>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                          <div
                            role="status"
                            className="max-w-sm animate-pulse inline"
                          >
                            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mx-auto"></div>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                          <div
                            role="status"
                            className="max-w-sm animate-pulse inline"
                          >
                            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mx-auto"></div>
                          </div>
                        </Table.Cell>

                        <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                          <div
                            role="status"
                            className="max-w-sm animate-pulse inline"
                          >
                            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mx-auto"></div>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </>
                )}
              </Table.Body>
            </Table>
          </div>
        ) : (
          <EmptyTable icon="search_off">
            Nenhum registro encontrado com esses filtros
          </EmptyTable>
        )}

        {totalPages > 1 && (
          <div className="flex overflow-x-auto justify-center mt-3">
            <Pagination
              layout="pagination"
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
              previousLabel=""
              nextLabel=""
              showIcons
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminPaymentsTable;
