import moment from "moment";
import React, { useEffect, useState } from "react";
import FilterToolbar from "../inputs/FilterToolbar";
import { Badge, Button, Pagination, Table } from "flowbite-react";
import { LuCalendarClock } from "react-icons/lu";
import { FaArrowsLeftRight } from "react-icons/fa6";
import axios from "../../config/axiosInstance";
import { useQuery } from "react-query";
import { QUERIES } from "../../config/apiConfig";
import { IoCloudDownload, IoEyeOutline } from "react-icons/io5";
import { IoMdEye } from "react-icons/io";
import { formatToReal } from "../../utils/utils";
import EmptyTable from "./EmptyTable";
import axiosInstance from "../../config/axiosInstance";

const DetailsTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const onPageChange = (page) => setCurrentPage(page);
  const [startDatePeriod, setStartDatePeriod] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDatePeriod, setEndDatePeriod] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(null);
  const [activeIndexLoading, setActiveIndexLoading] = useState(null);

  const {
    isLoadingListQueries,
    error: errorListQueries,
    data: listQueries,
  } = useQuery(
    ["listQueries", currentPage, startDatePeriod, endDatePeriod],
    async () => {
      const { data } = await axios.get(
        `${QUERIES.GET_QUERIES_LIST}?page=${currentPage}&startDate=${startDatePeriod}&endDate=${endDatePeriod}`
      );

      setTotalPages(data.data.totalPages || 1);

      return data.data.data;
    }
  );

  const onClickSearchPeriod = () => {
    setStartDatePeriod(startDate);
    setEndDatePeriod(endDate);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [startDatePeriod, endDatePeriod]);

  const handleDownloadDetails = async (
    index,
    fileName,
    queryId,
    data,
    parameter
  ) => {
    setLoadingDownload(true);
    setActiveIndexLoading(index);

    try {
      const dataFile = await axiosInstance.post(QUERIES.GENERATE_PDF, {
        parameter,
        queryId,
        data: JSON.stringify(data),
        token: localStorage.getItem("token"),
      });

      const url = dataFile.data.data;

      if (url) {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("target", "_blank");

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Erro ao iniciar o download:", error);
    } finally {
      setLoadingDownload(false);
      setActiveIndexLoading(null);
    }
  };

  return (
    <>
      <FilterToolbar
        title="Selecionar Período:"
        icon={<LuCalendarClock className="text-lg" />}
        isVisible={isVisibleFilter}
        setIsVisible={setIsVisibleFilter}
        style={{ background: "var(--bg-black)" }}
      >
        <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
          <input
            type="date"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
              if (moment(e.target.value).isAfter(moment(endDate))) {
                setEndDate(e.target.value);
              }
            }}
            className="w-full md:w-fit block py-3 md:py-2 px-2 rounded-md text-sm text-gray-600 bg-gray-100 border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          />
          <div className="text-sm text-white">Até</div>
          <input
            type="date"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
            min={startDatePeriod}
            className="w-full md:w-fit block py-3 md:py-2 px-2 rounded-md text-sm text-gray-600 bg-gray-100 border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          />
        </div>
        <Button onClick={onClickSearchPeriod} className="primary">
          Buscar
        </Button>
      </FilterToolbar>
      <div className="mt-10 text-sm md:text-md italic">
        Informações baseadas no período selecionado{" "}
        <Badge className="w-fit inline" color="info">
          {moment(startDatePeriod).format("DD/MM/YYYY")}
        </Badge>{" "}
        -{" "}
        <Badge className="w-fit inline" color="info">
          {moment(endDatePeriod).format("DD/MM/YYYY")}
        </Badge>
      </div>
      {listQueries?.length > 0 ? (
        <>
          <small
            className="gap-2 items-center mt-5 mb-1 flex md:hidden justify-center"
            style={{ fontSize: 10 }}
          >
            <span>
              Deslize para o lado para visualizar todas as informações
            </span>{" "}
            <FaArrowsLeftRight />
          </small>
          <div className="overflow-x-auto shadow-md rounded-lg mt-0 md:mt-5">
            <Table striped>
              {listQueries?.length > 0 ? (
                <Table.Head>
                  <Table.HeadCell className="text-center bg-gray-300">
                    Produto/consulta
                  </Table.HeadCell>
                  <Table.HeadCell className="text-center bg-gray-300">
                    Data/Hora
                  </Table.HeadCell>
                  <Table.HeadCell className="text-center bg-gray-300">
                    Parâmetro
                  </Table.HeadCell>
                  <Table.HeadCell className="text-center bg-gray-300">
                    Valor da consulta
                  </Table.HeadCell>
                  <Table.HeadCell className="text-center bg-gray-300">
                    Usuário
                  </Table.HeadCell>
                  <Table.HeadCell className="text-center bg-gray-300">
                    Status
                  </Table.HeadCell>
                  <Table.HeadCell className="text-center bg-gray-300">
                    Ações
                  </Table.HeadCell>
                </Table.Head>
              ) : (
                <></>
              )}

              <Table.Body className="divide-y">
                {!isLoadingListQueries ? (
                  <>
                    {listQueries?.length > 0 && (
                      <>
                        {listQueries.map((query, index) => (
                          <Table.Row
                            key={index}
                            className="bg-white dark:border-gray-700 dark:bg-gray-800"
                          >
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                              {query.queryName}
                            </Table.Cell>
                            <Table.Cell className="text-center py-2">
                              {moment(query.createdAt).format(
                                "DD/MM/YYYY - HH:mm"
                              )}
                              h
                            </Table.Cell>
                            <Table.Cell className="text-center py-2">
                              <div className="flex gap-1 justify-center items-center flex-wrap">
                                {query.parameter}
                              </div>
                            </Table.Cell>
                            <Table.Cell className="text-center py-2 font-bold">
                              {formatToReal(query.price)}
                            </Table.Cell>
                            <Table.Cell className="text-center py-2 font-bold">
                              <Badge className="w-fit mx-auto">
                                {query.userName}
                              </Badge>
                            </Table.Cell>
                            <Table.Cell className="text-center py-2 font-bold">
                              <Badge
                                className={`w-fit mx-auto`}
                                color={`${
                                  query.isSuccess === 0 ? "failure" : "green"
                                }`}
                              >
                                {query.isSuccess === 0 ? "Falha" : "Sucesso"}
                              </Badge>
                            </Table.Cell>
                            <Table.Cell className="text-center py-2">
                              <div className="flex gap-2 justify-center">
                                <Button
                                  className="primary"
                                  onClick={() =>
                                    handleDownloadDetails(
                                      index,
                                      query.fileName,
                                      query.QueryId,
                                      query.data,
                                      query.parameter
                                    )
                                  }
                                  isProcessing={
                                    loadingDownload &&
                                    activeIndexLoading === index
                                  }
                                >
                                  <IoCloudDownload fontSize="16" />
                                </Button>
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {Array.from({ length: 12 }).map((_, index) => (
                      <Table.Row
                        key={index}
                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                      >
                        <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                          <div
                            role="status"
                            className="max-w-sm animate-pulse inline"
                          >
                            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                          <div
                            role="status"
                            className="max-w-sm animate-pulse inline"
                          >
                            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                          <div
                            role="status"
                            className="max-w-sm animate-pulse inline"
                          >
                            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                          <div
                            role="status"
                            className="max-w-sm animate-pulse inline"
                          >
                            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                          <div
                            role="status"
                            className="max-w-sm animate-pulse inline"
                          >
                            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </>
                )}
              </Table.Body>
            </Table>
          </div>
        </>
      ) : (
        <EmptyTable icon="search_off">
          Nenhuma consulta encontrada para este período
        </EmptyTable>
      )}

      {totalPages > 1 && (
        <div className="flex overflow-x-auto justify-center mt-3">
          <Pagination
            layout="pagination"
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            previousLabel=""
            nextLabel=""
            showIcons
          />
        </div>
      )}
    </>
  );
};

export default DetailsTable;
