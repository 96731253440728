import { useEffect, useState } from "react";
import {
  calculateRiskScore,
  getColorProbability,
  getColorScore,
  getImageURLScore,
  getLetterScore,
} from "../../../utils/utils";
import ReactApexChart from "react-apexcharts";

export const EvaluationScore = ({
  data,
  score,
  probability,
  decisao = null,
}) => {
  const [riskScore, setRiskScore] = useState(0);

  useEffect(() => {
    setRiskScore(
      calculateRiskScore(
        +data?.resumoConsulta?.pendenciasFinanceiras?.quantidadeTotal || 0,
        +data?.resumoConsulta?.protestos?.quantidadeTotal || 0,
        +score
      )
    );
  }, [data]);

  return (
    <>
      <div className="flex gap-4">
        <div className="bg-gray-100 rounded-md shadow-md p-4 flex-1">
          <div className="title-syncx mb-3">Renda presumida</div>
          <div className="flex flex-col gap-2">
            <div className="bg-primary text-white rounded block px-2 py-1 w-fit text-xl">
              <span className="font-semibold">
                {data?.dadosCadastrais?.rendaPresumida || "Sem informação"}
              </span>
            </div>
            <div className="text-xs">
              {data?.dadosCadastrais?.textoRendaPresumida ||
                "Sem informação detalhada"}
            </div>
          </div>
        </div>
        <div className="bg-gray-100 rounded-md shadow-md p-4 flex-1">
          <div className="title-syncx mb-2">
            {decisao !== null ? "Decisão de crédito" : "Sugestão de negócio"}
          </div>
          {decisao !== null ? (
            <>
              {decisao == 1 ? (
                <>
                  <div className="bg-green-600 p-2 w-fit rounded font-semibold text-white">
                    Negociação recomendada
                  </div>
                  <div className="text-xs mt-2">
                    A análise demonstra alta confiabilidade e baixo risco.
                    Indicação favorável para concessão de crédito ou negócio.
                  </div>
                </>
              ) : (
                <>
                  <div className="bg-red-600 p-2 w-fit rounded font-semibold text-white">
                    Negociação não recomendada
                  </div>
                  <div className="text-xs mt-2">
                    A análise identificou fatores que comprometem a
                    confiabilidade do crédito, indicando alto risco. Não
                    recomendamos a aprovação neste momento.
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {score > 600 ? (
                <>
                  <div className="bg-green-600 p-2 w-fit rounded font-semibold text-white">
                    Negociação recomendada
                  </div>
                  <div className="text-xs mt-2">
                    A análise demonstra alta confiabilidade e baixo risco.
                    Indicação favorável para concessão de crédito ou negócio.
                  </div>
                </>
              ) : score > 400 ? (
                <>
                  <div className="bg-orange-400 p-2 w-fit rounded font-semibold text-white">
                    Negociação cautelosa
                  </div>
                  <div className="text-xs mt-2">
                    Há aspectos positivos na análise, mas também fatores que
                    requerem atenção. Recomenda-se proceder com prudência e
                    considerar garantias adicionais.
                  </div>
                </>
              ) : (
                <>
                  <div className="bg-red-500 p-2 w-fit rounded font-semibold text-white">
                    Negociação não recomendada
                  </div>
                  <div className="text-xs mt-2">
                    A análise identificou fatores que comprometem a
                    confiabilidade do crédito, indicando alto risco. Não
                    recomendamos a aprovação neste momento.
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>

      <div className="flex gap-4">
        <div className="flex-1 p-4 bg-gray-100 rounded-md shadow-md">
          <div className="mb-4 font-semibold title-syncx">Score de risco</div>
          <div className="pt-2 px-14 pb-0">
            <img src={getImageURLScore(score)} />
          </div>
          <div className="mb-2">
            <div
              className="font-semibold text-lg rounded p-2 w-fit mb-4 text-white shadow-md mx-auto mt-4"
              style={{
                background: `${getColorScore(score)}`,
              }}
            >
              Score {score}
            </div>
          </div>
        </div>
        <div className="flex-1 p-4 bg-gray-100 rounded-md shadow-md">
          <div className="title-syncx">Probabilidade de inadimplência</div>
          <ReactApexChart
            options={{
              chart: {
                height: 300,
                type: "radialBar",
                toolbar: {
                  show: false,
                },
              },
              plotOptions: {
                radialBar: {
                  startAngle: -135,
                  endAngle: 225,
                  hollow: {
                    margin: 0,
                    size: "70%",
                    background: "#fff",
                    image: undefined,
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    position: "front",
                    dropShadow: {
                      enabled: true,
                      top: 3,
                      left: 0,
                      blur: 4,
                      opacity: 0.5,
                    },
                  },
                  track: {
                    background: "#fff",
                    strokeWidth: "67%",
                    margin: 0, // margin is in pixels
                    dropShadow: {
                      enabled: true,
                      top: -3,
                      left: 0,
                      blur: 4,
                      opacity: 0.7,
                    },
                  },

                  dataLabels: {
                    show: true,
                    name: {
                      offsetY: -10,
                      show: true,
                      color: "#888",
                      fontSize: "17px",
                    },
                    value: {
                      formatter: function (val) {
                        return parseInt(val) + "%";
                      },
                      color: () => {
                        return "#000";
                      },
                      fontSize: "36px",
                      show: true,
                    },
                  },
                },
              },
              stroke: {
                lineCap: "round",
              },
              labels: ["Probabilidade"],
              colors: [getColorProbability(probability / 100)],
            }}
            series={[probability / 100]}
            type="radialBar"
            height={300}
          />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-full flex-1 bg-gray-100 rounded-md shadow-md p-4">
          <div className="relative">
            <div className="mb-5 font-semibold title-syncx">
              Score e classificação de risco
            </div>

            <div className="flex gap-10 items-center">
              <div className="mt-24 flex-1">
                <div className="relative">
                  <div
                    className="absolute bottom-9"
                    style={{ left: `${riskScore / 10 - 5}%` }}
                  >
                    <span
                      className="bg-primary text-white py-1 px-3 text-xl rounded-2xl font-semibold block text-center"
                      style={{ width: "60px" }}
                    >
                      {riskScore}
                    </span>
                    <div className="arrow-down"></div>
                  </div>
                  <img src="/assets/score/regua/regua.png" />
                </div>
                <div>
                  <div
                    className="font-semibold text-lg rounded p-2 w-fit mb-4 text-white shadow-md mx-auto mt-7"
                    style={{
                      background: `${getColorScore(riskScore)}`,
                    }}
                  >
                    Score {riskScore} - {getLetterScore(riskScore)}
                  </div>
                </div>
              </div>

              <div className="flex-1">
                <ul className="text-xs flex flex-col gap-2">
                  <li className="flex gap-2 items-start">
                    <span
                      className="block w-3 h-2 min-w-3 rounded-full mt-1"
                      style={{
                        background: `${getColorScore(900)}`,
                      }}
                    ></span>
                    <span>
                      <b>AAA</b> - Nota mais alta possível, indicando capacidade
                      excepcional de pagamento. O risco é extremamente baixo.
                    </span>
                  </li>
                  <li className="flex gap-2 items-start">
                    <span
                      className="block w-3 h-2 min-w-3 rounded-full mt-1"
                      style={{
                        background: `${getColorScore(700)}`,
                      }}
                    ></span>
                    <span>
                      <b>AA</b> - Muito alta capacidade de pagamento, mas com
                      leve risco maior que AAA.
                    </span>
                  </li>
                  <li className="flex gap-2 items-start">
                    <span
                      className="block w-3 h-2 min-w-3 rounded-full mt-1"
                      style={{
                        background: `${getColorScore(400)}`,
                      }}
                    ></span>
                    <span>
                      <b>B</b> - Entidade com capacidade de pagar, mas
                      dependente de condições favoráveis.
                    </span>
                  </li>
                  <li className="flex gap-2 items-start">
                    <span
                      className="block w-3 h-2 min-w-3 rounded-full mt-1"
                      style={{
                        background: `${getColorScore(200)}`,
                      }}
                    ></span>
                    <span>
                      <b>C</b> - Alto risco de inadimplência; dificuldades
                      financeiras sérias.
                    </span>
                  </li>
                  <li className="flex gap-2 items-start">
                    <span
                      className="block w-3 h-2 min-w-3 rounded-full mt-1"
                      style={{
                        background: `${getColorScore(70)}`,
                      }}
                    ></span>
                    <span>
                      <b>D</b> - Já em situação de inadimplência.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border border-gray-300 p-2 text-sm">
        <span className="font-semibold">Importante:</span> a decisão de
        aprovação ou não do crédito é de exclusiva responsabilidade do
        concedente. as informações prestadas pelo provedor tem como objevo
        subsidiar essas decisões e, em hipótese alguma devem ser ulizadas como
        justificativa, pelo concedente do crédito, para a tomada da referida
        decisão.
      </div>
    </>
  );
};
