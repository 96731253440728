import { Badge, Table } from "flowbite-react";
import { formatToReal } from "../../../utils/utils";

const ChequesSemFundos = ({
  chequesSemFundos,
  quantidadeTotal,
  totalNomes,
}) => {
  return (
    <>
      <div className="flex gap-6">
        {quantidadeTotal && (
          <div className="flex items-center gap-2">
            <span className="font-semibold text-red-700">Quantidade: </span>
            <Badge color="failure">{quantidadeTotal}</Badge>
          </div>
        )}

        {totalNomes && (
          <div className="flex items-center gap-2">
            <span className="font-semibold text-red-700">
              Total de Nomes Relacionados:{" "}
            </span>
            <Badge color="failure">{formatToReal(totalNomes)}</Badge>
          </div>
        )}
      </div>
      <div className="overflow-x-auto border-gray-100 border rounded-lg mt-3">
        <Table striped>
          <Table.Head>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Data
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Agência
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Nome do banco
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Quantidade
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Motivo
            </Table.HeadCell>
          </Table.Head>
          {chequesSemFundos?.length > 0 && (
            <Table.Body className="divide-y">
              {chequesSemFundos?.map((cheque, index) => (
                <Table.Row
                  key={index}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black"
                >
                  <Table.Cell className="font-medium dark:text-white text-center text-black py-1">
                    {cheque?.data || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center text-black py-1">
                    {cheque?.agencia || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center text-black py-1">
                    {cheque?.banco || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center text-red-500 font-semibold py-1">
                    {cheque?.quantidade || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center text-red-500 font-semibold py-1">
                    {cheque?.motivo || "-"}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </div>
    </>
  );
};

export default ChequesSemFundos;
