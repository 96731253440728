import React from "react";

const FooterQuery = ({ hasHeader = "true" }) => {
  return (
    <>
      {hasHeader === "true" && (
        <>
          <div className="mb-20"></div>
          <img
            className="absolute bottom-0 w-full left-0"
            src="/assets/banner_footer.png"
          />
        </>
      )}
    </>
  );
};

export default FooterQuery;
