import { Alert, Button, Card, Label, Table, TextInput } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { IoAlertCircleOutline } from "react-icons/io5";
import { formatToReal, isValidCPF } from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import moment from "moment";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import TitleStripe from "../common/TitleStripe";
import CardQueryDescription from "../common/CardQueryDescription";
import QueryInput from "../inputs/QueryInput";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FiAlertOctagon } from "react-icons/fi";
import SliderToPreview from "../common/SliderToPreview";
import QueryLoading from "../common/QueryLoading";
import AvisoCard from "../common/query/AvisoCard";
import NoOcurrence from "../common/query/NoOcurrence";
import Protestos from "../common/query/Protestos";
import AcoesJudiciais from "../common/query/AcoesJudiciais";
import HistoricoConsultas from "../common/query/HistoricoConsultas";
import PendenciasFinanceiras from "../common/query/PendenciasFinanceiras";
import ChequesSemFundos from "../common/query/ChequesSemFundos";
import FooterQuery from "./FooterQuery";

const RestritivaPFQuery = ({ query, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    data
  );

  const fetchInfo = async (raw) => {
    return await axios(
      `${QUERIES.GET_RESTRITIVA_PF}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCPF(unmaskedValue)) {
      setError("CPF inválido!");
      isValid = false;
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados.data["QuodReportResponseEx"].Response.Records.Record[0]);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const formatArrayPendencias = (arrayPendencias) => {
    return arrayPendencias.map((pendencia) => {
      return {
        data_debito: `${pendencia.DateOccurred.Day}/${pendencia.DateOccurred.Month}/${pendencia.DateOccurred.Year}`,
        valor: pendencia?.Amount,
        cidade: pendencia?.City,
        informante: pendencia?.CompanyName,
      };
    });
  };

  const formatArrayCCF = (arrayCCF) => {
    return arrayCCF.map((cheque) => {
      return {
        data_ultimo: `${cheque.DateLastBounce.Day}/${cheque.DateLastBounce.Month}/${cheque.DateLastBounce.Year}`,
        agencia: cheque?.agencia,
        nome_banco: cheque?.ReportingNameBank,
      };
    });
  };

  const formatArrayProtestos = (arrayProtestos) => {
    return arrayProtestos.map((protesto) => {
      return {
        data: protesto?.dataOcorrencia,
        valor: protesto?.valor_protestado,
        cidade: protesto?.cidade,
        cartorio: protesto?.nome,
        estado: protesto?.uf,
        vencimento: protesto?.data,
      };
    });
  };

  const formatArrayAcoesJudiciais = (arrayAcoes) => {
    return arrayAcoes.map((acao) => {
      return {
        data: `${acao?.DateProcessed?.Day}/${acao?.DateProcessed?.Month}/${acao?.DateProcessed?.Year}`,
        forum: acao?.Forum,
        tipoProcesso: acao?.ProcessType,
        cidade: acao?.City,
        valor: acao?.AmountLawsuit,
      };
    });
  };

  const formatArrayHistoricoConsultas = (arrayConsultas) => {
    return arrayConsultas.map((consulta) => {
      return {
        data: `${consulta?.DateInquiry?.Day}/${consulta?.DateInquiry?.Month}/${consulta?.DateInquiry?.Year}`,
        razao_social: consulta?.Segment,
      };
    });
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>

      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Negativações",
          "Ações judiciais",
          "Protestos",
          "Cheques sem fundos",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 mt-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex gap-2 items-end flex-col md:flex-row">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CPF"
            />
            {!errorQuery && data && (
              <Button
                className={`w-fit shadow-lg secondary ${
                  data && !isLoading ? "block" : "hidden"
                }`}
                isProcessing={pdfURL === ""}
                disabled={pdfURL === ""}
                onClick={() => handleClickDownloadPDF()}
              >
                Salvar como PDF
              </Button>
            )}
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <div
          className={`overflow-x-scroll ${
            data || isLoading ? "block" : "hidden"
          }`}
          ref={componentRef}
        >
          {!isLoading ? (
            <Card className="relative overflow-hidden card-pdf p-5">
              <HeaderQuery title={query?.name} />

              <TitleStripe>Quadro de avisos</TitleStripe>
              <div className="container-alerts four-columns">
                <AvisoCard
                  hasRestrictions={
                    data?.Negative?.Apontamentos?.Apontamento?.length > 0
                  }
                  title="Negativações"
                  messageError={`${data?.Negative?.Apontamentos?.Apontamento?.length} ocorrências`}
                />

                <AvisoCard
                  hasRestrictions={
                    data?.Negative?.CcfApontamentos?.CcfApontamento?.length > 0
                  }
                  title="Cheques Sem Fundos"
                  messageError={`${data?.Negative?.CcfApontamentos?.CcfApontamento?.length} ocorrências`}
                />

                <AvisoCard
                  hasRestrictions={
                    data?.Negative?.LawSuitApontamentos?.LawSuitApontamento
                      ?.length > 0
                  }
                  title="Ações Judiciais"
                  messageError={`${data?.Negative?.LawSuitApontamentos?.LawSuitApontamento?.length} ocorrências`}
                />

                <AvisoCard
                  hasRestrictions={
                    data?.Protests?.Protest[0]?.situacao === "CONSTA"
                  }
                  title="Protestos"
                  messageError={`${formatToReal(
                    data?.Protests?.Protest[0]?.valor_protestados_total
                  )}`}
                />
              </div>
              <div>
                <TitleStripe className="mb-3">Negativações</TitleStripe>
                {data?.Negative?.Apontamentos?.Apontamento?.length > 0 ? (
                  <PendenciasFinanceiras
                    pendenciasFinanceiras={formatArrayPendencias(
                      data?.Negative?.Apontamentos?.Apontamento
                    )}
                  />
                ) : (
                  <NoOcurrence />
                )}
              </div>

              <div>
                <TitleStripe className="mb-3">Cheques sem fundos</TitleStripe>
                {data?.Negative?.CcfApontamentos?.CcfApontamento?.length > 0 ? (
                  <ChequesSemFundos
                    chequesSemFundos={formatArrayCCF(
                      data?.Negative?.CcfApontamentos?.CcfApontamento
                    )}
                  />
                ) : (
                  <NoOcurrence />
                )}
              </div>

              <div>
                <TitleStripe className="mb-3">Ações judiciais</TitleStripe>
                {data?.Negative?.LawSuitApontamentos?.LawSuitApontamento
                  ?.length > 0 ? (
                  <AcoesJudiciais
                    acoesJudiciais={formatArrayAcoesJudiciais(
                      data?.Negative?.LawSuitApontamentos?.LawSuitApontamento
                    )}
                  />
                ) : (
                  <NoOcurrence />
                )}
              </div>
              <div>
                <div>
                  {!data?.Protests?.Protest[0]?.erro_descricao && (
                    <>
                      <TitleStripe className="mb-3">Protestos</TitleStripe>
                      <Protestos
                        protestos={formatArrayProtestos(
                          data?.Protests?.Protest[0]?.conteudo?.cartorio
                        )}
                      />
                    </>
                  )}
                </div>
              </div>

              <div>
                <TitleStripe className="mb-3">
                  Histórico de consultas
                </TitleStripe>
                {data?.Inquiries?.InquiryDetails?.InquiryDetail?.length > 0 ? (
                  <HistoricoConsultas
                    historicoConsultas={formatArrayHistoricoConsultas(
                      data?.Inquiries?.InquiryDetails?.InquiryDetail
                    )}
                  />
                ) : (
                  <NoOcurrence />
                )}
              </div>

              <FooterQuery hasHeader={true} />
            </Card>
          ) : (
            <QueryLoading />
          )}
        </div>
      )}
    </>
  );
};

export default RestritivaPFQuery;
