import { Badge, Table } from "flowbite-react";

const FalenciaRecuperacaoJudicial = ({ falenciaRecuperacaoJudicial }) => {
  return (
    <>
      <div className="overflow-x-auto border-gray-100 border rounded-lg mt-3">
        <Table className="text-sm" striped>
          <Table.Head>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Tipo
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Data
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Razão Social
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Cidade/UF
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Vara
            </Table.HeadCell>
          </Table.Head>
          {falenciaRecuperacaoJudicial?.length > 0 && (
            <Table.Body className="divide-y">
              {falenciaRecuperacaoJudicial?.map((ocorrencia, index) => (
                <Table.Row
                  key={index}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                >
                  <Table.Cell className="font-medium text-black dark:text-white text-center py-1">
                    {ocorrencia?.tipo || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center py-1 text-black">
                    {ocorrencia?.data || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center py-1 text-black">
                    {ocorrencia?.razaoSocial || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center py-1 text-black">
                    {ocorrencia?.cidade || "-"}/{ocorrencia?.uf || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center py-1 text-black">
                    {ocorrencia?.vara || "-"}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </div>
    </>
  );
};

export default FalenciaRecuperacaoJudicial;
