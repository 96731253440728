import { Badge, Table } from "flowbite-react";
import { formatToReal } from "../../../utils/utils";

const QuadroSocietario = ({ socios }) => {
  return (
    <>
      <div className="overflow-x-auto border-gray-100 border rounded-lg mt-3">
        <Table striped>
          <Table.Head className="text-black">
            <Table.HeadCell
              className="text-center py-2 px-1"
              style={{ background: "#76b9fc" }}
            >
              Nº
            </Table.HeadCell>
            <Table.HeadCell className="py-2" style={{ background: "#76b9fc" }}>
              Nome
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center py-2 px-1"
              style={{ background: "#76b9fc" }}
            >
              Data <br /> Entrada
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center py-2 px-1"
              style={{ background: "#76b9fc" }}
            >
              Part. (%)
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center py-2 px-1"
              style={{ background: "#76b9fc" }}
            >
              Assina <br /> Empresa?
              <br /> (S/N)
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center py-2 px-1"
              style={{ background: "#76b9fc" }}
            >
              Débitos
              <br />
              (S/N)
            </Table.HeadCell>
            <Table.HeadCell className="py-2" style={{ background: "#76b9fc" }}>
              Pendências
            </Table.HeadCell>
          </Table.Head>
          {socios?.length > 0 && (
            <Table.Body className="divide-y">
              {socios?.map((socio, index) => (
                <Table.Row
                  key={index}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black"
                >
                  <Table.Cell className="font-medium dark:text-white text-center py-1 px-1">
                    {index + 1}
                  </Table.Cell>
                  <Table.Cell className=" py-1">
                    <div>{socio?.nome_empresa || "-"}</div>
                    <div style={{ fontSize: "10px" }}>
                      CPF/CNPJ: {socio?.cpf_cnpj}
                    </div>
                  </Table.Cell>
                  <Table.Cell className="text-center py-1 px-1">
                    {socio?.data_entrada || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center py-1 px-1 px-1">
                    {socio?.participacao || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center py-1">
                    {socio?.assina_empresa || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center py-1 px-1">
                    {socio?.indicador_debito?.toLowerCase() === "sim" ? (
                      <div className="text-red-600 mx-auto">
                        {socio?.indicador_debito || "-"}
                      </div>
                    ) : (
                      <div className="text-blue-600 mx-auto font-semibold">
                        {socio?.indicador_debito || "-"}
                      </div>
                    )}
                  </Table.Cell>
                  <Table.Cell className="py-1 text-2xs text-black">
                    {socio?.indicador_debito?.toUpperCase() === "SIM" ? (
                      <>
                        {socio?.restricoes_documento?.cheques_sem_fundo
                          ?.quantidade !== "0" && (
                          <div className="uppercase">
                            Cheques Sem Fundos:{" "}
                            <span className="text-red-500 font-semibold">
                              {
                                socio?.restricoes_documento?.cheques_sem_fundo
                                  ?.quantidade
                              }{" "}
                              ocorrências
                            </span>
                          </div>
                        )}

                        {socio?.restricoes_documento?.protestos?.quantidade !==
                          "0" && (
                          <div className="uppercase">
                            Protestos:{" "}
                            <span className="text-red-500 font-semibold">
                              {formatToReal(
                                socio?.restricoes_documento?.protestos?.valor?.replace(
                                  ",",
                                  "."
                                )
                              )}
                            </span>
                          </div>
                        )}

                        {socio?.restricoes_documento?.pendencias_restricoes
                          ?.quantidade !== "0" && (
                          <div className="uppercase">
                            Pendências:{" "}
                            <span className="text-red-500 font-semibold">
                              {formatToReal(
                                socio?.restricoes_documento?.pendencias_restricoes?.valor?.replace(
                                  ",",
                                  "."
                                )
                              )}
                            </span>
                          </div>
                        )}

                        {socio?.restricoes_documento?.acoes_judiciais
                          ?.quantidade !== "0" && (
                          <div className="uppercase">
                            Ações cíveis:{" "}
                            <span className="text-red-500 font-semibold">
                              {formatToReal(
                                socio?.restricoes_documento?.acoes_judiciais?.valor?.replace(
                                  ",",
                                  "."
                                )
                              )}
                            </span>
                          </div>
                        )}

                        {socio?.restricoes_documento
                          ?.falencias_recuperacao_judicial?.quantidade !==
                          "0" && (
                          <div className="uppercase">
                            Rec. Judicial/Falências:{" "}
                            <span className="text-red-500 font-semibold">
                              {formatToReal(
                                socio?.restricoes_documento?.falencias_recuperacao_judicial?.valor?.replace(
                                  ",",
                                  "."
                                )
                              )}
                            </span>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="text-blue-500">Não há débitos</div>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </div>
    </>
  );
};

export default QuadroSocietario;
