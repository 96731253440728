import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Checkbox,
  Label,
  Pagination,
  Spinner,
  Table,
} from "flowbite-react";
import { FaArrowsLeftRight } from "react-icons/fa6";
import axios from "../../config/axiosInstance";
import { useQuery } from "react-query";
import { ADMIN, CATEGORIES, QUERIES, USERS } from "../../config/apiConfig";
import { formatToReal } from "../../utils/utils";
import { IoMdSearch } from "react-icons/io";
import AutoCompleteInput from "../inputs/Autocomplete";
import EmptyTable from "./EmptyTable";
import { MdOutlineAttachMoney } from "react-icons/md";
import { BsFillBarChartFill } from "react-icons/bs";
import DrawerLoading from "../DrawerLoading";
import { IoCloudDownload } from "react-icons/io5";
import axiosInstance from "../../config/axiosInstance";

const AdminHistoryTable = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalPriceSum, setTotalPriceSum] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const onPageChange = (page) => setCurrentPage(page);
  const [searchName, setSearchName] = useState("");
  const [nameParam, setNameParam] = useState("");
  const [selectedQueries, setSelectedQueries] = useState([]);
  const [listCategoryQueries, setListCategoryQueries] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({
    label: "Todos os clientes",
    value: 0,
  });
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [loadingDownload, setLoadingDownload] = useState(null);
  const [activeIndexLoading, setActiveIndexLoading] = useState(null);

  const {
    isLoading: isLoadingQueryPermissions,
    error: errorQueryPermissions,
    data: userQueryPermissions,
  } = useQuery(["userQueryPermissions"], async () => {
    const { data: items } = await axios.get(
      `${USERS.GET_USER_QUERY_PERMISSIONS}`
    );

    return items?.data?.map((query) => query.id);
  });

  const {
    isLoading: isLoadingCustomers,
    error: errorListCustomers,
    data: listCustomers,
  } = useQuery(["listCustomers"], async () => {
    try {
      const { data } = await axios.get(`${ADMIN.GET_CUSTOMERS}?itemsPerPage=-1`);

      if (!data?.data?.data) {
        throw new Error("Erro ao buscar clientes");
      }

      let customers = data.data.data.map((customer) => ({
        label:
          customer.typeCustomer === "CPF"
            ? customer.name
            : customer.socialReason,
        value: customer.id,
      }));

      customers = [{ label: "Todos os clientes", value: 0 }, ...customers];

      return customers;
    } catch (error) {
      throw new Error(`Erro ao buscar clientes: ${error.message}`);
    }
  });

  const {
    isLoadingCategories,
    error: errorCategories,
    data: categories,
  } = useQuery(["categories", currentPage], async () => {
    const { data } = await axios.get(`${CATEGORIES.GET_ALL}`);

    return data.data.data;
  });

  const {
    isLoading: isLoadingReportQueries,
    error: errorListReportQueries,
    data: listReportQueries,
  } = useQuery(
    [
      "listReportQueries",
      selectedCustomer,
      startDate,
      endDate,
      currentPage,
      selectedQueries,
      selectedStatuses,
      nameParam,
    ],
    async () => {
      const { data } = await axios.get(
        `${ADMIN.GET_COMPANY_QUERIES_HISTORY}/${selectedCustomer?.value}?startDate=${startDate}&endDate=${endDate}&page=${currentPage}&status=${selectedStatuses}&queries=${selectedQueries}&search=${nameParam}`
      );

      setTotalPages(data.data.totalPages);
      setTotalPriceSum(data.data.totalPriceSum);
      setTotalCount(data.data.totalCount);

      return data.data.data;
    }
  );

  const {
    isLoading: isLoadingReportSimplifiedQueries,
    error: errorListReportSimplifiedQueries,
    data: listReportSimplifiedQueries,
  } = useQuery(
    [
      "listReportSimplifiedQueries",
      selectedCustomer,
      startDate,
      endDate,
      currentPage,
      selectedQueries,
      selectedStatuses,
    ],
    async () => {
      const { data } = await axios.get(
        `${ADMIN.GET_COMPANY_QUERIES_HISTORY_SIMPLIFIED}/${selectedCustomer?.value}?startDate=${startDate}&endDate=${endDate}&page=${currentPage}&status=${selectedStatuses}&queries=${selectedQueries}`
      );

      setTotalPages(data.data.totalPages);
      setTotalPriceSum(data.data.totalSumPrice || 0);
      setTotalCount(data.data.totalSumCount);

      return data.data.data;
    },
    {
      enabled: activeTab === 1,
    }
  );

  const handleCheckboxChange = (status, setState) => {
    setState((prev) =>
      prev.includes(status)
        ? prev.filter((item) => item !== status)
        : [...prev, status]
    );
  };

  const handleSearchName = (e) => {
    e.preventDefault();
    setNameParam(searchName);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab]);

  useEffect(() => {
    setListCategoryQueries(() =>
      categories?.map((category) => {
        return {
          ...category,
          Queries: category?.Queries?.filter((query) =>
            userQueryPermissions?.includes(query.id)
          ),
        };
      })
    );
  }, [userQueryPermissions, categories]);

  const handleDownloadDetails = async (
    index,
    fileName,
    queryId,
    data,
    parameter
  ) => {
    setLoadingDownload(true);
    setActiveIndexLoading(index);

    try {
      const dataFile = await axiosInstance.post(QUERIES.GENERATE_PDF, {
        parameter,
        queryId,
        data: JSON.stringify(data),
        token: localStorage.getItem("token"),
      });

      const url = dataFile.data.data;

      if (url) {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("target", "_blank");

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Erro ao iniciar o download:", error);
    } finally {
      setLoadingDownload(false);
      setActiveIndexLoading(null);
    }
  };

  return (
    <div className="flex md:flex-row flex-col gap-4">
      <div className="w-full md:w-80 bg-white rounded-md shadow-md p-4 relative">
        {(isLoadingCategories ||
          isLoadingCustomers ||
          isLoadingQueryPermissions) && <DrawerLoading />}
        <div className="title-syncx">Filtro avançado</div>
        <div className="mt-6">
          <AutoCompleteInput
            options={listCustomers}
            label="Cliente"
            onChange={(customer) => setSelectedCustomer(customer)}
            value={selectedCustomer?.value}
            isLoading={isLoadingCustomers}
          />
          {errorListCustomers && (
            <Alert color="failure">Ocorreu um erro ao buscar clientes</Alert>
          )}
        </div>

        <div className="font-semibold mt-4 text-sm mb-2">Período</div>
        <div className="flex items-center gap-1">
          <input
            type="date"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
              if (moment(e.target.value).isAfter(moment(endDate))) {
                setEndDate(e.target.value);
              }
            }}
            className="w-full block py-3 md:py-2 px-2 rounded-md text-xs text-gray-600 bg-gray-100 border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          />
          <div className="text-xs">Até</div>
          <input
            type="date"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
            min={startDate}
            className="w-full block py-3 md:py-2 px-2 rounded-md text-xs text-gray-600 bg-gray-100 border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          />
        </div>
        <div className="mt-4 text-sm">
          <div className="font-semibold">Status</div>
          <ul className="mt-2 flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <Checkbox
                id="sucesso"
                checked={selectedStatuses.includes(1)}
                onChange={() => handleCheckboxChange(1, setSelectedStatuses)}
              />
              <Label htmlFor="sucesso" className="flex">
                Sucesso
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                id="falha"
                checked={selectedStatuses.includes(0)} // 0 = Falha
                onChange={() => handleCheckboxChange(0, setSelectedStatuses)}
              />
              <Label htmlFor="falha" className="flex">
                Falha
              </Label>
            </div>
          </ul>
        </div>

        {listCategoryQueries?.map((category, idxCat) => (
          <div key={idxCat}>
            {category?.Queries?.length > 0 && (
              <div className="mt-4 text-sm">
                <div className="font-semibold">{category.name}</div>
                <ul className="mt-2 flex flex-col gap-2">
                  {category?.Queries?.map((query, index) => (
                    <div className="flex items-center gap-2" key={index}>
                      <Checkbox
                        id={`query-${query.id}`}
                        checked={selectedQueries.includes(query.id)}
                        onChange={() =>
                          handleCheckboxChange(query.id, setSelectedQueries)
                        }
                      />
                      <Label htmlFor={`query-${query.id}`} className="flex">
                        {query.name}
                      </Label>
                    </div>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="flex-1">
        <div className="title-syncx mt-3 mb-4">Resultado da busca</div>
        <div className="overflow-x-scroll">
          <div className="flex gap-3 pb-3" style={{ minWidth: "500px" }}>
            <Card
              className="w-full bg-gray-700 relative"
              style={{ minWidth: "150px" }}
            >
              <div className="text-sm md:text-md lg:text-lg text-white">
                Valor Total:
              </div>
              <div className="text-2xl text-white font-semibold flex gap-2 items-center">
                {!isLoadingReportQueries ? (
                  `${formatToReal(totalPriceSum)}`
                ) : (
                  <div role="status" className="max-w-sm animate-pulse inline">
                    <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                  </div>
                )}
              </div>
              <MdOutlineAttachMoney className="absolute bottom-0 right-0 text-white text-9xl opacity-10" />
            </Card>
            <Card className="w-full bg-gray-700 relative overflow-hidden">
              <div className="text-sm md:text-md lg:text-lg text-white">
                Total de consultas:
              </div>
              <div className="text-2xl text-white font-semibold">
                {!isLoadingReportQueries ? (
                  `${totalCount}`
                ) : (
                  <div role="status" className="max-w-sm animate-pulse inline">
                    <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                  </div>
                )}
              </div>
              <BsFillBarChartFill className="absolute bottom-0 right-2 text-white text-9xl opacity-10" />
            </Card>
          </div>
        </div>
        <div className="mb-4 flex justify-end items-center gap-2">
          <div className="text-xs font-semibold">Visualizar relatório:</div>
          <ul className="text-sm rounded-md border border-gray-300 flex">
            <li
              className={`py-2 px-4 rounded-md cursor-pointer ${
                activeTab === 0
                  ? `bg-primary text-white font-semibold shadow-md`
                  : `bg-white`
              }`}
              onClick={() => setActiveTab(0)}
            >
              Detalhado
            </li>
            <li
              className={`py-2 px-4 rounded-md cursor-pointer ${
                activeTab === 1
                  ? `bg-primary text-white font-semibold shadow-md`
                  : `bg-white`
              }`}
              onClick={() => setActiveTab(1)}
            >
              Simplificado
            </li>
          </ul>
        </div>
        {activeTab === 0 && (
          <form
            className="flex gap-1 mt-0 w-full pl-3 pr-2 py-2 md:py-1 rounded-md shadow-sm bg-gray-300"
            onSubmit={handleSearchName}
          >
            <input
              type="text"
              placeholder="Busque por parâmetro da consulta..."
              className="border-0 py-1 text-sm mt-1 focus:outline-none focus:ring-0 focus:border-gray-300 px-0 w-full bg-gray-300 placeholder:text-gray-700"
              onChange={(e) => setSearchName(e.target.value)}
              value={searchName}
            />
            <Button
              size="sm"
              className="flex items-center bg-secondary w-20"
              type="submit"
            >
              <IoMdSearch />
            </Button>
          </form>
        )}

        <small
          className="gap-2 items-center mt-5 mb-1 flex md:hidden justify-center"
          style={{ fontSize: 10 }}
        >
          <span>Deslize para o lado para visualizar todas as informações</span>{" "}
          <FaArrowsLeftRight />
        </small>
        {activeTab === 0 ? (
          <>
            {listReportQueries?.length > 0 || isLoadingReportQueries ? (
              <div className="overflow-x-auto shadow-md rounded-lg mt-0 md:mt-5">
                <Table striped style={{ fontSize: "13px" }}>
                  <Table.Head>
                    <Table.HeadCell className="text-center bg-gray-300">
                      Data
                    </Table.HeadCell>
                    <Table.HeadCell className="text-center bg-gray-300">
                      Produto/Consulta
                    </Table.HeadCell>
                    <Table.HeadCell className="text-center bg-gray-300">
                      Valor da consulta
                    </Table.HeadCell>
                    <Table.HeadCell className="text-center bg-gray-300">
                      Parâmetro
                    </Table.HeadCell>
                    <Table.HeadCell className="text-center bg-gray-300">
                      Usuário
                    </Table.HeadCell>
                    <Table.HeadCell className="text-center bg-gray-300">
                      Status
                    </Table.HeadCell>
                    <Table.HeadCell className="text-center bg-gray-300">
                      Ações
                    </Table.HeadCell>
                  </Table.Head>

                  <Table.Body className="divide-y">
                    {!isLoadingReportQueries ? (
                      <>
                        {listReportQueries?.length > 0 && (
                          <>
                            {listReportQueries.map((query, index) => (
                              <Table.Row
                                key={index}
                                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              >
                                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                                  {moment(query.createdAt).format(
                                    "DD/MM/yyyy | HH:mm"
                                  )}
                                </Table.Cell>
                                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                                  {query.queryName}
                                </Table.Cell>
                                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                                  {formatToReal(query?.price)}
                                </Table.Cell>
                                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                                  {query.parameter}
                                </Table.Cell>
                                <Table.Cell className="text-center py-2">
                                  {query.userName}
                                </Table.Cell>
                                <Table.Cell className="text-center py-2">
                                  {query.isSuccess ? (
                                    <Badge
                                      color="success"
                                      className="w-fit mx-auto text-xs"
                                    >
                                      Sucesso
                                    </Badge>
                                  ) : (
                                    <Badge
                                      color="failure"
                                      className="w-fit mx-auto text-xs"
                                    >
                                      Falha
                                    </Badge>
                                  )}
                                </Table.Cell>
                                <Table.Cell className="text-center py-2">
                                  <div className="flex gap-2 justify-center">
                                    <button
                                      className="bg-primary shadow w-8 h-7 flex items-center text-white justify-center rounded-md"
                                      onClick={() =>
                                        handleDownloadDetails(
                                          index,
                                          query.fileName,
                                          query.QueryId,
                                          query.data,
                                          query.parameter
                                        )
                                      }
                                    >
                                      {loadingDownload &&
                                      activeIndexLoading === index ? (
                                        <Spinner size="sm" />
                                      ) : (
                                        <IoCloudDownload fontSize="16" />
                                      )}
                                    </button>
                                  </div>
                                </Table.Cell>
                              </Table.Row>
                            ))}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {Array.from({ length: 12 }).map((_, index) => (
                          <Table.Row
                            key={index}
                            className="bg-white dark:border-gray-700 dark:bg-gray-800"
                          >
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                              <div
                                role="status"
                                className="max-w-sm animate-pulse inline"
                              >
                                <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mx-auto"></div>
                              </div>
                            </Table.Cell>
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                              <div
                                role="status"
                                className="max-w-sm animate-pulse inline"
                              >
                                <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mx-auto"></div>
                              </div>
                            </Table.Cell>
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                              <div
                                role="status"
                                className="max-w-sm animate-pulse inline"
                              >
                                <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mx-auto"></div>
                              </div>
                            </Table.Cell>
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                              <div
                                role="status"
                                className="max-w-sm animate-pulse inline"
                              >
                                <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mx-auto"></div>
                              </div>
                            </Table.Cell>
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                              <div
                                role="status"
                                className="max-w-sm animate-pulse inline"
                              >
                                <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mx-auto"></div>
                              </div>
                            </Table.Cell>
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                              <div
                                role="status"
                                className="max-w-sm animate-pulse inline"
                              >
                                <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mx-auto"></div>
                              </div>
                            </Table.Cell>
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                              <div
                                role="status"
                                className="max-w-sm animate-pulse inline"
                              >
                                <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mx-auto"></div>
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </>
                    )}
                  </Table.Body>
                </Table>
              </div>
            ) : (
              <EmptyTable icon="search_off">
                Nenhum registro encontrado com esses filtros
              </EmptyTable>
            )}
          </>
        ) : (
          <>
            {listReportSimplifiedQueries?.length > 0 ||
            isLoadingReportSimplifiedQueries ? (
              <div className="overflow-x-auto shadow-md rounded-lg mt-0 md:mt-5">
                <Table striped style={{ fontSize: "13px" }}>
                  <Table.Head>
                    <Table.HeadCell className="text-center bg-gray-300">
                      Produto/Consulta
                    </Table.HeadCell>
                    <Table.HeadCell className="text-center bg-gray-300">
                      Qtd. Total
                    </Table.HeadCell>
                    <Table.HeadCell className="text-center bg-gray-300">
                      Valor Total
                    </Table.HeadCell>
                  </Table.Head>

                  <Table.Body className="divide-y">
                    {!isLoadingReportSimplifiedQueries ? (
                      <>
                        {listReportSimplifiedQueries?.length > 0 && (
                          <>
                            {listReportSimplifiedQueries.map((query, index) => (
                              <Table.Row
                                key={index}
                                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              >
                                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                                  {query.queryName}
                                </Table.Cell>
                                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                                  {query.count}
                                </Table.Cell>
                                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                                  {formatToReal(query?.totalPrice || 0)}
                                </Table.Cell>
                              </Table.Row>
                            ))}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {Array.from({ length: 12 }).map((_, index) => (
                          <Table.Row
                            key={index}
                            className="bg-white dark:border-gray-700 dark:bg-gray-800"
                          >
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                              <div
                                role="status"
                                className="max-w-sm animate-pulse inline"
                              >
                                <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40 mx-auto"></div>
                              </div>
                            </Table.Cell>
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                              <div
                                role="status"
                                className="max-w-sm animate-pulse inline"
                              >
                                <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40 mx-auto"></div>
                              </div>
                            </Table.Cell>
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                              <div
                                role="status"
                                className="max-w-sm animate-pulse inline"
                              >
                                <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40 mx-auto"></div>
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </>
                    )}
                  </Table.Body>
                </Table>
              </div>
            ) : (
              <EmptyTable icon="search_off">
                Nenhum registro encontrado com esses filtros
              </EmptyTable>
            )}
          </>
        )}

        {totalPages > 1 && (
          <div className="flex overflow-x-auto justify-center mt-3">
            <Pagination
              layout="pagination"
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
              previousLabel=""
              nextLabel=""
              showIcons
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminHistoryTable;
