import React, { useEffect, useState } from "react";
import { QUERIES } from "../config/apiConfig";
import axios from "../config/axiosInstance";
import { uploadPDF } from "../utils/utils";

const usePDF = (componentRef, info, queryId, data) => {
  const [pdfURL, setPdfURL] = useState("");

  const handleClickDownloadPDF = async () => {
    try {
      const binaryString = atob(pdfURL);
      const len = binaryString.length;
      const arrayBuffer = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        arrayBuffer[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([arrayBuffer], { type: "application/pdf" });

      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `${info}.pdf`;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Erro ao abrir PDF:", error);
    }
  };

  useEffect(() => {
    const upload = async () => {
      const response = await uploadPDF(componentRef, info, queryId);
      setPdfURL(response || "");
    };

    setTimeout(() => {
      if (data) {
        upload();
      }
    }, 1000);
  }, [data]);

  return { pdfURL, setPdfURL, handleClickDownloadPDF };
};

export default usePDF;
