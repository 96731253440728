import { Badge, Table } from "flowbite-react";
import { formatToReal } from "../../../utils/utils";

const ChequesSustados = ({
  chequesSustados,
  periodoInicial,
  periodoFinal,
  quantidadeTotal,
}) => {
  return (
    <>
      <div className="flex gap-6 flex-wrap">
        {periodoInicial && periodoFinal && (
          <div className="flex gap-1 items-center">
            <span className="font-semibold text-red-700">Período: </span>
            <Badge color="failure">
              {" "}
              {periodoInicial} até
              {periodoFinal}
            </Badge>
          </div>
        )}

        {quantidadeTotal && (
          <div className="flex gap-1 items-center">
            <span className="font-semibold text-red-700">Quantidade: </span>
            <Badge color="failure">{quantidadeTotal}</Badge>
          </div>
        )}
      </div>
      <div className="overflow-x-auto border-gray-100 border rounded-lg mt-3">
        <Table striped>
          <Table.Head>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Data
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Banco
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Agência
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Cheque
            </Table.HeadCell>
          </Table.Head>
          {chequesSustados?.length > 0 && (
            <Table.Body className="divide-y">
              {chequesSustados?.map((cheque, index) => (
                <Table.Row
                  key={index}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                >
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center py-1">
                    {cheque?.data || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center py-1">
                    {cheque?.banco || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center py-1">
                    {cheque?.agencia || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center py-1">
                    {cheque?.cheques || "-"}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </div>
    </>
  );
};

export default ChequesSustados;
