import { formatToReal } from "../../utils/utils";
import { AdditionalsQuery } from "../queries/AdditionalsQuery";

const CardQueryDescription = ({
  title,
  infos,
  children,
  price,
  imageBanner = "/assets/bg_queries.png",
}) => {
  return (
    <div
      className="relative bg-gray-200 shadow-md rounded-lg bg-cover p-5 mb-5"
      style={{ backgroundImage: `url('${imageBanner}')` }}
    >
      <div className="relative">
        <img
          src="/assets/logo-azul.png"
          className="w-28 hidden md:block mb-5"
        />
        <div className="font-semibold text-xl md:text-2xl mt-5 text-blue-900">
          {title}
        </div>
        <div className="font-semibold mt-2">
          Valor da consulta: {formatToReal(price)}
        </div>
        {infos?.length > 0 && (
          <ul
            className="mt-3 flex flex-wrap gap-2 mb-5 text-sm"
            style={{ maxWidth: "480px" }}
          >
            {infos?.map((info, index) => (
              <li
                key={index}
                className={`relative ${
                  index + 1 !== infos.length
                    ? "after:content-['|'] after:absolute after:right-0 after:pl-2"
                    : ""
                }  pr-3`}
              >
                {info}
              </li>
            ))}
          </ul>
        )}
        {/* <div className="mb-2 font-semibold text-sm">Incremente ainda mais sua consulta:</div>
        <div className="w-fit mb-4">
          <AdditionalsQuery />
        </div> */}
        {children}
      </div>
    </div>
  );
};

export default CardQueryDescription;
