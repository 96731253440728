let BASE_URL = "";

if (process.env.NODE_ENV === "development") {
  BASE_URL = "http://localhost:3000";
} else if (process.env.NODE_ENV === "production") {
  BASE_URL = "https://app.acessopositivo.com.br";
}

BASE_URL += "/api";

const LOGIN = {
  POST_LOGIN: BASE_URL + "/login",
};

const API = {
  GET_TOKEN: "/get-api-rest-token",
};

const CATEGORIES = {
  GET_ALL: "/categories",
};

const COMPANY = {
  GET_PERMISSION_QUERIES: "/company/permission/queries",
};

const USERS = {
  GET_USERS: "/users",
  GET_USER: "/user",
  GET_USER_CURRENT: "/user-current",
  GET_USER_QUERY_PERMISSIONS: "/user-query/permission",
};

const ADMIN = {
  GET_CUSTOMERS: "/admin/customers",
  GET_REPRESENTATIVES: "/admin/representatives",
  GET_COMPANY_QUERIES: "/admin/company/queries",
  GET_QUERIES: "/admin/queries",
  GET_USERS: "/admin/users",
  NEW_CUSTOMER: "/admin/customer/new",
  NEW_REPRESENTATIVE: "/admin/representative/new",
  GET_COMPANY_BY_INFO: "/admin/company/info",
  GET_COMPANY: "/admin/company",
  GET_COMPANY_QUERIES_HISTORY: "/admin/queries/company",
  GET_COMPANY_QUERIES_HISTORY_SIMPLIFIED: "/admin/queries/company/simplified",
  GET_PAYMENTS: "/admin/payments",
  GET_QUERY: "/admin/query",
  GET_TABLE_QUERIES: "/admin/table/queries",
  GET_TABLES: "/admin/tables",
  GET_TABLE: "/admin/table",
};

const QUERIES = {
  GET_DEFINE_RISCO: "/query/positivo-plus-cnpj",
  GET_DEFINE_LIMITE: "/query/define-limite",
  GET_DEFINE_LIMITE_POSITIVO: "/query/define-limite-positivo",
  GET_ACERTA_ESSENCIAL: "/query/positivo-plus-cpf",
  GET_ACERTA_COMPLETO: "/query/acerta-completo",
  GET_AVALIE: "/query/avalie",
  GET_COMPLETA_PLUS: "/query/completa-plus-cnpj",
  GET_COMPLETA_PLUS_CPF: "/query/completa-plus-cpf",
  GET_SERASA_BASICO: "/query/serasa-basico",
  GET_SCR_BACEN_SCORE: "/query/scr-bacen-score",
  GET_CADASTRAL_PF: "/query/cadastral-pf",
  GET_CADIN: "/query/cadin",
  GET_RESTRITIVA_PF: "/query/restritiva-pf",
  GET_CADASTRAL_PJ: "/query/cadastral-pj",
  GET_RESTRITIVA_PJ: "/query/restritiva-pj",
  GET_PROTESTO_NACIONAL: "/query/protesto-nacional",
  GET_REGULARIZE: "/query/regularize",
  GET_LOCALIZA_TELEFONE: "/query/localiza-telefone",
  GET_LOCALIZA_CPF: "/query/localiza-cpf",
  GET_LOCALIZA_CNPJ: "/query/localiza-cnpj",
  GET_CREDITO_PREMIUM_CPF: "/query/credito-premium-cpf",
  GET_CREDITO_PREMIUM_CNPJ: "/query/credito-premium-cnpj",
  GET_SERASA_PREMIUM_CPF: "/query/serasa-premium-cpf",
  GET_SERASA_PREMIUM_CNPJ: "/query/serasa-premium-cnpj",
  GET_INFOCRED_CPF: "/query/infocred-cpf",
  GET_INFOCRED_CNPJ: "/query/infocred-cnpj",
  GET_ACESSO_NET_CPF: "/query/acesso-net-cpf",
  GET_LOCALIZA_CNPJ: "/query/localiza-cnpj",
  GET_LAST_QUERIES: "/query/last",
  GET_LAST_7_DAYS_COUNT_QUERIES: "/query/total/last/7",
  GET_QUERIES_TOTAL: "/query/total",
  GET_QUERIES_LIST: "/queries",
  UPLOAD_PDF: "/query/upload/pdf",
  DOWNLOAD_PDF: "/query/download/pdf",
  GENERATE_PDF: "/generate/pdf",
  GET_DASHBOARD_INFOS: "/query/dashboard",
  GET_DASHBOARD_INFOS_REPRESENTATIVE: "/query/dashboard/representative",
  GET_DASHBOARD_INFOS_POWER_USER: "/query/dashboard/power-user",
};

const QUERIES_VEICULAR = {
  GET_COMPLETA_VEICULAR: "/query/completa-veicular",
  GET_LEILAO_POSITIVO: "/query/leilao-positivo",
};

const AUDITS = {
  GET_AUDITS: "/audits",
};

const AVATAR = {
  UPLOAD: "/avatar/upload",
};

const PAYMENTS = {
  CREATE: "/payment/create",
  GET_ALL: "/invoices",
  ADD_CREDIT: "/payment/confirm",
  ADD_CREDIT_MANUALLY: "/payment/manually",
};

const REPORTS = {
  GET_COMPANY_QUERIES_HISTORY: "/report/queries/company",
  GET_COMPANY_QUERIES_HISTORY_SIMPLIFIED: "/report/queries/company/simplified",
};

export {
  API,
  LOGIN,
  CATEGORIES,
  COMPANY,
  USERS,
  ADMIN,
  QUERIES,
  QUERIES_VEICULAR,
  AUDITS,
  AVATAR,
  PAYMENTS,
  REPORTS,
  BASE_URL,
};
