import { Button, Card, Label, Table, TextInput } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaRegCheckCircle, FaSearch } from "react-icons/fa";
import { IoAlertCircleOutline } from "react-icons/io5";
import { isValidCPF } from "../../utils/utils";
import ReactApexChart from "react-apexcharts";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { maskCNPJ } from "../../utils/masks";

const CompletaPlusCNPJForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [data, setData] = useState(null);

  const handleChange = (event) => {
    let valor = event.target.value.replace(/\D/g, "");

    valor = maskCNPJ(valor);

    setInfo(valor);
  };

  const fetchInfo = async (raw) => {
    return await axios(
      `${QUERIES.GET_ACERTA_ESSENCIAL}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCPF(unmaskedValue)) {
      setError("CNPJ inválido!");
      isValid = false;
    }

    if (isValid) {
      setData(null);
      setIsLoading(true);
      setError("");
      const { data: dados } = await fetchInfo();
      setData(dados.data);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        Consulta Completa Plus CNPJ
      </div>
      <Card className="mb-4">
        <form className="flex max-w-lg flex-col gap-2" onSubmit={handleSubmit}>
          <div className="flex gap-2 items-end">
            <div className="flex items-center gap-2">
              <div className="block">
                <Label htmlFor="info" value="CNPJ:" />
              </div>
              <TextInput
                id="info"
                type="text"
                required
                onChange={handleChange}
                maxLength="14"
                value={info}
              />
            </div>
            <Button
              className=" shadow-lg primary flex items-center justify-center"
              type="submit"
              isProcessing={isLoading}
              processingSpinner={<CgSpinner className="h-5 w-5 animate-spin" />}
              style={{ height: "43px" }}
            >
              <FaSearch />
            </Button>
          </div>
          {error && <small className="text-red-600">{error}</small>}
        </form>
      </Card>
      <div className={`${!data && "hidden"}`}>
        {!isLoading ? (
          <Card>
            <div className="title-syncx">Dados da consulta</div>
            <div>
              <div className="mb-3">
                <span className="font-semibold">Tipo de consulta:</span>{" "}
                {data?.HEADER.INFORMACOES_RETORNO.PRODUTO}{" "}
              </div>
              <div className="mb-3">
                <span className="font-semibold">Dados utilizados:</span> CPF:
                {data?.CREDCADASTRAL.IDENTIFICACAO_PESSOA_FISICA.CPF_NUMERO}
              </div>
              <div className="mb-3">
                <span className="font-semibold">Nº da consulta:</span>{" "}
                {data?.HEADER.INFORMACOES_RETORNO.CHAVE_CONSULTA}
              </div>
              <div className="mb-3">
                <span className="font-semibold">Data | Hora:</span>{" "}
                {data?.HEADER.INFORMACOES_RETORNO.DATA_HORA_CONSULTA}
              </div>
            </div>
            <div className="title-syncx mt-5">Quadro de avisos</div>
            <div>
              <Card
                className={`${
                  data?.CREDCADASTRAL.CH_SEM_FUNDOS_BACEN
                    .QUANTIDADE_OCORRENCIA == 0
                    ? "bg-green-200"
                    : "bg-red-300"
                } mb-3`}
              >
                <div className="flex gap-2 items-center text-gray-600 font-semibold">
                  <div>
                    <FaRegCheckCircle fontSize={20} />
                  </div>
                  <div>
                    Cheques sem fundos BACEN:{" "}
                    {data?.CREDCADASTRAL.CH_SEM_FUNDOS_BACEN
                      .QUANTIDADE_OCORRENCIA == 0
                      ? "Nada consta"
                      : `${data?.CREDCADASTRAL.CH_SEM_FUNDOS_BACEN.QUANTIDADE_OCORRENCIA} ocorrências`}
                  </div>
                </div>
              </Card>
              <Card
                className={`${
                  data?.CREDCADASTRAL.CH_SEM_FUNDOS_VAREJO
                    .QUANTIDADE_OCORRENCIA == 0
                    ? "bg-green-200"
                    : "bg-red-300"
                } mb-3`}
              >
                <div className="flex gap-2 items-center text-gray-600 font-semibold">
                  <div>
                    <FaRegCheckCircle fontSize={20} />
                  </div>
                  <div>
                    Cheques sem fundos Varejo:{" "}
                    {data?.CREDCADASTRAL.CH_SEM_FUNDOS_VAREJO
                      .QUANTIDADE_OCORRENCIA == 0
                      ? "Nada consta"
                      : `${data?.CREDCADASTRAL.CH_SEM_FUNDOS_VAREJO.QUANTIDADE_OCORRENCIA} ocorrências`}
                  </div>
                </div>
              </Card>
              <Card
                className={`${
                  data?.CREDCADASTRAL.CONTUMACIA.QUANTIDADE_OCORRENCIA == 0
                    ? "bg-green-200"
                    : "bg-red-300"
                } mb-3`}
              >
                <div className="flex gap-2 items-center text-gray-600 font-semibold">
                  <div>
                    <FaRegCheckCircle fontSize={20} />
                  </div>
                  <div>
                    Contumacia:{" "}
                    {data?.CREDCADASTRAL.CONTUMACIA.QUANTIDADE_OCORRENCIA == 0
                      ? "Nada consta"
                      : `${data?.CREDCADASTRAL.CONTUMACIA.QUANTIDADE_OCORRENCIA} ocorrências`}
                  </div>
                </div>
              </Card>
              <Card
                className={`${
                  data?.CREDCADASTRAL.PEND_FINANCEIRAS.QUANTIDADE_OCORRENCIA ==
                  0
                    ? "bg-green-200"
                    : "bg-red-300"
                } mb-3`}
              >
                <div className="flex gap-2 items-center text-gray-600 font-semibold">
                  <div>
                    <IoAlertCircleOutline fontSize={20} />
                  </div>
                  <div>
                    Pendências financeiras:{" "}
                    {data?.CREDCADASTRAL.PEND_FINANCEIRAS
                      .QUANTIDADE_OCORRENCIA == 0
                      ? "Nada consta"
                      : `${data?.CREDCADASTRAL.PEND_FINANCEIRAS.QUANTIDADE_OCORRENCIA} ocorrências`}
                  </div>
                </div>
              </Card>
              <Card
                className={`${
                  data?.CREDCADASTRAL.PROTESTOS.QUANTIDADE_OCORRENCIA == 0
                    ? "bg-green-200"
                    : "bg-red-300"
                } mb-3`}
              >
                <div className="flex gap-2 items-center text-gray-600 font-semibold">
                  <div>
                    <IoAlertCircleOutline fontSize={20} />
                  </div>
                  <div>
                    Títulos protestados:{" "}
                    {data?.CREDCADASTRAL.PROTESTOS.QUANTIDADE_OCORRENCIA == 0
                      ? "Nada consta"
                      : `${data?.CREDCADASTRAL.PROTESTOS.QUANTIDADE_OCORRENCIA} ocorrências`}
                  </div>
                </div>
              </Card>
            </div>
            <div className="title-syncx mt-5">Informações da pessoa:</div>
            <div className="flex flex-col md:flex-row gap-3 md:gap-20">
              <div>
                <div className="mb-3">
                  <span className="font-semibold">Nome completo: </span>
                  {data?.CREDCADASTRAL.IDENTIFICACAO_PESSOA_FISICA.NOME}
                </div>
                <div className="mb-3">
                  <span className="font-semibold">Telefone: </span>
                  {data?.CREDCADASTRAL.IDENTIFICACAO_PESSOA_FISICA.TELEFONE}
                </div>
                <div className="mb-3">
                  <span className="font-semibold">Data de nascimento: </span>
                  {data?.CREDCADASTRAL.IDENTIFICACAO_PESSOA_FISICA.NASCIMENTO}
                </div>
                <div className="mb-3">
                  <span className="font-semibold">Situação: </span>
                  {data?.CREDCADASTRAL.IDENTIFICACAO_PESSOA_FISICA.CPF_SITUACAO}
                </div>
                <div className="mb-3">
                  <span className="font-semibold">Data de atualização: </span>
                  {
                    data?.CREDCADASTRAL.IDENTIFICACAO_PESSOA_FISICA
                      .CPF_DATA_ATUALIZACAO_RECEITA
                  }
                </div>
                <div className="mb-3">
                  <span className="font-semibold">Sexo: </span>
                  {data?.CREDCADASTRAL.IDENTIFICACAO_PESSOA_FISICA.SEXO}
                </div>
                <div className="mb-3">
                  <span className="font-semibold">Nome da mãe:</span>
                  {data?.CREDCADASTRAL.IDENTIFICACAO_PESSOA_FISICA.MAE}
                </div>
              </div>
              <div>
                <div className="mb-3">
                  <span className="font-semibold">Origem CPF: </span>
                  {data?.CREDCADASTRAL.IDENTIFICACAO_PESSOA_FISICA.CPF_ORIGEM}
                </div>
                <div className="mb-3">
                  <span className="font-semibold">Estado Civil: </span>
                  {data?.CREDCADASTRAL.IDENTIFICACAO_PESSOA_FISICA.ESTADO_CIVIL}
                </div>
                <div className="mb-3">
                  <span className="font-semibold">Endereço: </span>
                  {
                    data?.CREDCADASTRAL.IDENTIFICACAO_PESSOA_FISICA
                      .END_LOGRADOURO
                  }
                </div>
                <div className="mb-3">
                  <span className="font-semibold">Bairro: </span>
                  {data?.CREDCADASTRAL.IDENTIFICACAO_PESSOA_FISICA.END_BAIRRO}
                </div>
                <div className="mb-3">
                  <span className="font-semibold">Cidade/UF: </span>
                  {data?.CREDCADASTRAL.IDENTIFICACAO_PESSOA_FISICA.END_CIDADE}
                </div>
                <div className="mb-3">
                  <span className="font-semibold">CEP: </span>
                  {data?.CREDCADASTRAL.IDENTIFICACAO_PESSOA_FISICA.END_CEP}
                </div>
              </div>
            </div>
            <div>
              <div className="title-syncx mt-5">Score de risco</div>
            </div>
            <div className="flex flex-col gap-2 md:flex-row items-center">
              <div className="w-full md:w-3/5 p-4">
                <ReactApexChart
                  options={{
                    chart: {
                      type: "radialBar",
                      offsetY: -20,
                      sparkline: {
                        enabled: true,
                      },
                    },
                    plotOptions: {
                      radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                          background: "#e7e7e7",
                          strokeWidth: "97%",
                          margin: 5, // margin is in pixels
                          dropShadow: {
                            enabled: true,
                            top: 2,
                            left: 0,
                            color: "#999",
                            opacity: 1,
                            blur: 2,
                          },
                        },
                        dataLabels: {
                          name: {
                            show: false,
                          },
                          value: {
                            offsetY: -2,
                            fontSize: "22px",
                            formatter: function (val) {
                              return val * 10;
                            },
                          },
                        },
                      },
                    },
                    grid: {
                      padding: {
                        top: -10,
                      },
                    },
                    fill: {
                      type: "solid",
                      gradient: {
                        shade: "light",
                        shadeIntensity: 0.4,
                        inverseColors: false,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 50, 53, 91],
                      },
                      colors: (() => {
                        const score =
                          +data?.CREDCADASTRAL.SCORES.OCORRENCIAS[0].SCORE;
                        if (score >= 800) {
                          return "#01B000";
                        } else if (score >= 600) {
                          return "#8DED01";
                        } else if (score >= 400) {
                          return "#FDF700";
                        } else if (score >= 200) {
                          return "#FFBC00";
                        } else {
                          return "#E54B00";
                        }
                      })(),
                    },
                  }}
                  series={[
                    +data?.CREDCADASTRAL.SCORES.OCORRENCIAS[0].SCORE / 10,
                  ]}
                  type="radialBar"
                />
              </div>
              <div className="w-full">
                <div className="mb-2">
                  <div className="text-red-500">
                    Probabilidade de inadimplência ={" "}
                    {
                      data?.CREDCADASTRAL.SCORES.OCORRENCIAS[0]
                        .PROBABILIDADE_INADIMPLENCIA
                    }
                  </div>{" "}
                  {data?.CREDCADASTRAL.SCORES.OCORRENCIAS[0].TEXTO.toLowerCase()}
                </div>
              </div>
            </div>
            <small>
              <span className="font-semibold">Importante:</span> a decisão de
              aprovação ou não do crédito é de exclusiva responsabilidade do
              concedente. as informações prestadas pelo provedor tem como objevo
              subsidiar essas decisões e, em hipótese alguma devem ser ulizadas
              como justificativa, pelo concedente do crédito, para a tomada da
              referida decisão.
            </small>
            <div>
              <div className="title-syncx mt-5 mb-3">Renda Presumida</div>
              <div className="mb-3">
                <span className="font-semibold">Faixa: </span>
                {data?.CREDCADASTRAL.RENDA_PRESUMIDA.FAIXA}
              </div>
              <div className="mb-3">
                <span className="font-semibold">Descrição: </span>
                {data?.CREDCADASTRAL.RENDA_PRESUMIDA.DESCRICAO}
              </div>
              <div className="mb-3">
                <span className="font-semibold">Observações: </span>
                {data?.CREDCADASTRAL.RENDA_PRESUMIDA.MENSAGEM}
              </div>
            </div>
            <div>
              <div className="title-syncx mt-5">
                Informações, alertas ou restrições
              </div>
              <Card
                className={`${
                  data?.CREDCADASTRAL.INFORMACOES_ALERTAS_RESTRICOES
                    .QUANTIDADE_OCORRENCIA == 0
                    ? "bg-green-200"
                    : "bg-red-300"
                } mt-4`}
              >
                <div className="flex gap-2 items-center text-gray-600 font-semibold">
                  <div>
                    <IoAlertCircleOutline fontSize={20} />
                  </div>
                  <div>
                    {data?.CREDCADASTRAL.INFORMACOES_ALERTAS_RESTRICOES
                      .QUANTIDADE_OCORRENCIA == 0
                      ? "Nada consta"
                      : `${data?.CREDCADASTRAL.INFORMACOES_ALERTAS_RESTRICOES.QUANTIDADE_OCORRENCIA} ocorrências`}
                  </div>
                </div>
              </Card>
            </div>
            <div>
              <div className="title-syncx mt-5 mb-4">
                Pendências financeiras
              </div>
              {data?.CREDCADASTRAL.PEND_FINANCEIRAS.OCORRENCIAS?.length > 0 ? (
                <>
                  <div className="flex flex-col md:flex-row gap-3 md:gap-6">
                    <div>
                      <span className="font-semibold">Quantidade: </span>
                      {
                        data?.CREDCADASTRAL.PEND_FINANCEIRAS
                          .QUANTIDADE_OCORRENCIA
                      }
                    </div>
                    <div>
                      <span className="font-semibold">Último vencimento: </span>
                      {data?.CREDCADASTRAL.PEND_FINANCEIRAS.ULTIMO_VENCIMENTO}
                    </div>
                    <div>
                      <span className="font-semibold">Valor Total: </span>
                      R$ {data?.CREDCADASTRAL.PEND_FINANCEIRAS.VALOR_TOTAL}
                    </div>
                  </div>
                  <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                    <Table striped>
                      <Table.Head>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Vencimento
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Contrato
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Valor
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Tipo:
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Credor:
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Base:
                        </Table.HeadCell>
                      </Table.Head>

                      <Table.Body className="divide-y">
                        {data?.CREDCADASTRAL.PEND_FINANCEIRAS.OCORRENCIAS.map(
                          (ocorrencia, index) => (
                            <Table.Row
                              key={index}
                              className="bg-white dark:border-gray-700 dark:bg-gray-800"
                            >
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                {ocorrencia.DATA_VENCIMENTO}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {ocorrencia.CONTRATO}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                R$ {ocorrencia.VALOR}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {ocorrencia.TIPO_DEVEDOR}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {ocorrencia.CREDOR}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {ocorrencia.INFORMANTE}
                              </Table.Cell>
                            </Table.Row>
                          )
                        )}
                      </Table.Body>
                    </Table>
                  </div>
                </>
              ) : (
                <Card className={`bg-green-200 mt-4`}>
                  <div className="flex gap-2 items-center text-gray-600 font-semibold">
                    <div>
                      <IoAlertCircleOutline fontSize={20} />
                    </div>
                    <div>Nada consta</div>
                  </div>
                </Card>
              )}
            </div>
            <div>
              <div className="title-syncx mt-5 mb-4">Protestos - Analítico</div>
              {data?.CREDCADASTRAL.PROTESTOS.OCORRENCIAS?.length > 0 ? (
                <>
                  <div className="flex flex-col md:flex-row gap-3 md:gap-6">
                    <div>
                      <span className="font-semibold">Quantidade: </span>
                      {data?.CREDCADASTRAL.PROTESTOS.QUANTIDADE_OCORRENCIA}
                    </div>
                    <div>
                      <span className="font-semibold">Primeiro valor: </span>
                      {data?.CREDCADASTRAL.PROTESTOS.VALOR_PRIMEIRO}
                    </div>
                    <div>
                      <span className="font-semibold">Valor Total: </span>
                      R$ {data?.CREDCADASTRAL.PROTESTOS.VALOR_TOTAL}
                    </div>
                  </div>
                  <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                    <Table striped>
                      <Table.Head>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Data
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Valor
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Credor
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Cartório
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Qtd.
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Origem:
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Tipo Anotação:
                        </Table.HeadCell>
                      </Table.Head>

                      <Table.Body className="divide-y">
                        {data?.CREDCADASTRAL.PROTESTOS.OCORRENCIAS.map(
                          (protesto, index) => (
                            <Table.Row
                              key={index}
                              className="bg-white dark:border-gray-700 dark:bg-gray-800"
                            >
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                {protesto.DATA}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                R$ {protesto.VALOR}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                R$ {protesto.CREDOR}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {protesto.CARTORIO}º cartório
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {+protesto.QUANTIDADE || 1}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {protesto.ORIGEM}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {protesto.TIPO_ANOTACAO || "-"}
                              </Table.Cell>
                            </Table.Row>
                          )
                        )}
                      </Table.Body>
                    </Table>
                  </div>
                </>
              ) : (
                <Card className={`bg-green-200 mt-4`}>
                  <div className="flex gap-2 items-center text-gray-600 font-semibold">
                    <div>
                      <IoAlertCircleOutline fontSize={20} />
                    </div>
                    <div>Nada consta</div>
                  </div>
                </Card>
              )}
            </div>
            <div>
              <div className="title-syncx mt-5 mb-4">
                Cheques devolvidos - BACEN
              </div>
              {data?.CREDCADASTRAL.CH_SEM_FUNDOS_BACEN.OCORRENCIAS?.length >
              0 ? (
                <>
                  <div className="flex flex-col md:flex-row gap-3 md:gap-6 flex-wrap">
                    <div>
                      <span className="font-semibold">Correntista: </span>
                      {data?.CREDCADASTRAL.CH_SEM_FUNDOS_BACEN.CORRENTISTA}
                    </div>
                    <div>
                      <span className="font-semibold">CPF/CNPJ: </span>
                      {data?.CREDCADASTRAL.CH_SEM_FUNDOS_BACEN.CPFCNPJ}
                    </div>
                    <div>
                      <span className="font-semibold">Tipo pessoa: </span>
                      {data?.CREDCADASTRAL.CH_SEM_FUNDOS_BACEN.TIPO_PESSOA}
                    </div>
                    <div>
                      <span className="font-semibold">Quantidade: </span>
                      {
                        data?.CREDCADASTRAL.CH_SEM_FUNDOS_BACEN
                          .QUANTIDADE_OCORRENCIA
                      }
                    </div>
                  </div>
                  <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                    <Table striped>
                      <Table.Head>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Data
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Banco
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Agência
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Motivo/Alínea
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Qtd.
                        </Table.HeadCell>
                      </Table.Head>

                      <Table.Body className="divide-y">
                        {data?.CREDCADASTRAL.CH_SEM_FUNDOS_BACEN.OCORRENCIAS.map(
                          (cheque, index) => (
                            <Table.Row
                              key={index}
                              className="bg-white dark:border-gray-700 dark:bg-gray-800"
                            >
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                {cheque.DT_ULTIMA_OCORRENCIA}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {cheque.DCR_BANCO}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {cheque.NUM_AGENCIA}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {cheque.MOTIVO_DEVOLUCAO}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {+cheque.QTD_CHEQUES}
                              </Table.Cell>
                            </Table.Row>
                          )
                        )}
                      </Table.Body>
                    </Table>
                  </div>
                </>
              ) : (
                <Card className={`bg-green-200 mt-4`}>
                  <div className="flex gap-2 items-center text-gray-600 font-semibold">
                    <div>
                      <IoAlertCircleOutline fontSize={20} />
                    </div>
                    <div>Nada consta</div>
                  </div>
                </Card>
              )}
            </div>
            <div>
              <div className="title-syncx mt-5 mb-4">Contumácia</div>
              {data?.CREDCADASTRAL.CONTUMACIA.OCORRENCIAS?.length > 0 ? (
                <>
                  <div className="flex flex-col md:flex-row gap-3 md:gap-6">
                    <div>
                      <span className="font-semibold">Quantidade: </span>
                      {data?.CREDCADASTRAL.CONTUMACIA.QUANTIDADE_OCORRENCIA}
                    </div>
                    <div>
                      <span className="font-semibold">Período: </span>
                      {
                        data?.CREDCADASTRAL.CONTUMACIA.DATA_PRIMEIRA_OCORRENCIA
                      }{" "}
                      até
                      {data?.CREDCADASTRAL.CONTUMACIA.DATA_ULTIMA_OCORRENCIA}
                    </div>
                  </div>
                  <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                    <Table striped>
                      <Table.Head>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Data
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Agência
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Banco
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Nº cheque Inicial
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Nº cheque Final
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Motivo devolução:
                        </Table.HeadCell>
                      </Table.Head>

                      <Table.Body className="divide-y">
                        {data?.CREDCADASTRAL.CONTUMACIA.OCORRENCIAS.map(
                          (contumacia, index) => (
                            <Table.Row
                              key={index}
                              className="bg-white dark:border-gray-700 dark:bg-gray-800"
                            >
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                {contumacia.DATA_OCORRENCIA}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {contumacia.AGENCIA}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {contumacia.BANCO}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {contumacia.NUMERO_CHEQUE_INICIAL}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {+contumacia.NUMERO_CHEQUE_FINAL}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {contumacia.MOTIVO_DEVOLUCAO}
                              </Table.Cell>
                            </Table.Row>
                          )
                        )}
                      </Table.Body>
                    </Table>
                  </div>
                </>
              ) : (
                <Card className={`bg-green-200 mt-4`}>
                  <div className="flex gap-2 items-center text-gray-600 font-semibold">
                    <div>
                      <IoAlertCircleOutline fontSize={20} />
                    </div>
                    <div>Nada consta</div>
                  </div>
                </Card>
              )}
            </div>
            <div>
              <div className="title-syncx mt-5 mb-4">
                Passagens comerciais / consultas
              </div>

              {data?.CREDCADASTRAL.PASSAGENS_COMERCIAIS.OCORRENCIAS?.length >
              0 ? (
                <>
                  <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                    <Table striped>
                      <Table.Head>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Data
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Empresa
                        </Table.HeadCell>
                        <Table.HeadCell className="text-center bg-gray-300">
                          Origem
                        </Table.HeadCell>
                      </Table.Head>

                      <Table.Body className="divide-y">
                        {data?.CREDCADASTRAL.PASSAGENS_COMERCIAIS.OCORRENCIAS.map(
                          (consult, index) => (
                            <Table.Row
                              key={index}
                              className="bg-white dark:border-gray-700 dark:bg-gray-800"
                            >
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                {consult.DATA_CONSULTA}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {consult.CLIENTE_CONSULTA}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {consult.CIDADE_UF_CLIENTE}
                              </Table.Cell>
                            </Table.Row>
                          )
                        )}
                      </Table.Body>
                    </Table>
                  </div>
                </>
              ) : (
                <Card className={`bg-green-200 mt-4`}>
                  <div className="flex gap-2 items-center text-gray-600 font-semibold">
                    <div>
                      <IoAlertCircleOutline fontSize={20} />
                    </div>
                    <div>Nada consta</div>
                  </div>
                </Card>
              )}
            </div>
          </Card>
        ) : (
          <Card>
            <div role="status" className="w-full animate-pulse">
              <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-10"></div>
              <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-10 mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
              <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
            </div>
          </Card>
        )}
      </div>
    </>
  );
};

export default CompletaPlusCNPJForm;
