import domtoimage from "dom-to-image";
import { PDFDocument } from "pdf-lib";

const arrayBufferToBase64 = (buffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  bytes.forEach((byte) => {
    binary += String.fromCharCode(byte);
  });
  return btoa(binary);
};

export const handleDownloadPdf = async (element) => {
  try {
    // Salva o estilo original
    const originalStyle = element.style.cssText;

    // Define estilo de desktop temporário
    element.style.width = "1050px";
    element.style.height = "auto";
    element.style.transform = "scale(1)";

    // Captura a imagem do elemento
    const dataUrl = await domtoimage.toPng(element);

    // Restaura o estilo original
    element.style.cssText = originalStyle;

    // Carrega a imagem em um elemento Image
    const img = new Image();
    img.src = dataUrl;

    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
    });

    // Cria um documento PDF
    const pdfDoc = await PDFDocument.create();

    // Define as dimensões do PDF com base na imagem capturada
    const pdfWidth = img.width * 0.75; // Ajuste para pontos do PDF (72 DPI)
    const pdfHeight = img.height * 0.75;

    const page = pdfDoc.addPage([pdfWidth, pdfHeight]);

    // Insere a imagem no PDF
    const pdfImage = await pdfDoc.embedPng(dataUrl);

    page.drawImage(pdfImage, {
      x: 0,
      y: 0,
      width: pdfWidth,
      height: pdfHeight,
    });

    // Salva o PDF como bytes
    const modifiedPdfBytes = await pdfDoc.save();

    const base64Pdf = arrayBufferToBase64(modifiedPdfBytes);

    return base64Pdf;
  } catch (error) {
    console.error("Erro ao gerar o PDF:", error);
  }
};
