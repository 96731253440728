import React from "react";
import { Alert, Badge, Card, Table } from "flowbite-react";
import { CodeBlock, CopyBlock, dracula } from "react-code-blocks";
import { QUERIES } from "../../config/apiConfig";
import TitleStripe from "../common/TitleStripe";

const DocDefineRisco = ({ token }) => {
  return (
    <Card className={`w-full mt-3`}>
      <div className="mb-4">
        <h2 className="title-syncx">Documentação da API: Positivo Plus CNPJ</h2>
        <br /> Esta documentação descreve como integrar-se à API utilizando um
        método <b>GET</b> com autenticação baseada em token. <br />
        Siga as instruções abaixo para enviar uma solicitação autenticada e
        entender a resposta da API.
        <br />
        <br />
        Endpoint URL:{" "}
        <Badge className="inline" color="indigo">
          https://app.acessopositivo.com.br/api{QUERIES.GET_DEFINE_RISCO}/:CNPJ
        </Badge>{" "}
        <br />
        Método:{" "}
        <Badge className="inline" color="indigo">
          GET
        </Badge>
        <br />
        Autenticação:{" "}
        <Badge className="inline" color="indigo">
          Bearer Token
        </Badge>
        <br />
        <br />
        <div className="font-semibold">Token:</div>
        <CopyBlock text={token} theme={dracula} codeBlock />
        <Alert className="mt-2" color="warning" withBorderAccent>
          Não divulgue esse token com ninguém! O token é valido por 2 anos. Caso
          o token expire, entrar em contato diretamente com o suporte.
        </Alert>
        <div className="mt-4">
          <TitleStripe>Parâmetros da requisição</TitleStripe>
          <div className="overflow-x-auto shadow-lg rounded-lg mt-0 md:mt-5">
            <Table striped>
              <Table.Head>
                <Table.HeadCell className="text-center bg-gray-300">
                  Parâmetro
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Tipo
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Obrigatório
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Observações
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    CNPJ
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Texto
                  </Table.Cell>
                  <Table.Cell className="text-center text-red-500">
                    Sim
                  </Table.Cell>
                  <Table.Cell className="text-center">
                    Informar o CNPJ a ser consultado no parâmetro da URL.
                  </Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    generatePDF
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Texto (true|false)
                  </Table.Cell>
                  <Table.Cell className="text-center">Não</Table.Cell>
                  <Table.Cell className="text-center">
                    Caso queira gerar o pdf e retornar junto a resposta, colocar
                    o parametro como true
                  </Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    hasHeaderPDF
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Texto (true|false)
                  </Table.Cell>
                  <Table.Cell className="text-center">Não</Table.Cell>
                  <Table.Cell className="text-center">
                    Caso queira esconder o cabeçalho da empresa no PDF, colocar
                    o parametro como false
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>
        <br />
        <span className="text-sm -mt-2 block italic">
          Obs: Envie o token de autenticação no cabeçalho da requisição.
        </span>
      </div>
      <div>
        <TitleStripe>Parâmetros da resposta</TitleStripe>
        <div className="overflow-x-auto shadow-lg rounded-lg mt-0 md:mt-5">
          <Table striped>
            <Table.Head>
              <Table.HeadCell className="text-center bg-gray-300">
                Chave
              </Table.HeadCell>
              <Table.HeadCell className="text-center bg-gray-300">
                Tipo
              </Table.HeadCell>
              <Table.HeadCell className="text-center bg-gray-300">
                Observações
              </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                  dadosCadastrais
                </Table.Cell>
                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                  Objeto
                </Table.Cell>
                <Table.Cell className="text-center">
                  Dados Cadastrais
                </Table.Cell>
              </Table.Row>
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                  resumoConsulta
                </Table.Cell>
                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                  Objeto
                </Table.Cell>
                <Table.Cell className="text-center">
                  Resumo da consulta, caso haja pendências, irá aparecer o
                  resumo
                </Table.Cell>
              </Table.Row>
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                  Score
                </Table.Cell>
                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                  Objeto
                </Table.Cell>
                <Table.Cell className="text-center">
                  Score de crédito
                </Table.Cell>
              </Table.Row>
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                  pendenciasFinanceiras
                </Table.Cell>
                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                  Array
                </Table.Cell>
                <Table.Cell className="text-center">
                  Lista de todas as pendências financeiras
                </Table.Cell>
              </Table.Row>
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                  protestos
                </Table.Cell>
                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                  Array
                </Table.Cell>
                <Table.Cell className="text-center">
                  Lista de todas os protestos
                </Table.Cell>
              </Table.Row>
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                  chequesSemFundo
                </Table.Cell>
                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                  Array
                </Table.Cell>
                <Table.Cell className="text-center">
                  Lista de todas os cheques sem fundo
                </Table.Cell>
              </Table.Row>
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                  chequesSustados
                </Table.Cell>
                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                  Array
                </Table.Cell>
                <Table.Cell className="text-center">
                  Lista de todas os cheques sustados
                </Table.Cell>
              </Table.Row>
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                  historicoConsultas
                </Table.Cell>
                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                  Array
                </Table.Cell>
                <Table.Cell className="text-center">
                  Lista de todas as últimas consultas
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      </div>
      <TitleStripe>Exemplo de resposta</TitleStripe>
      <CodeBlock
        text={`{
          "status": "success",
          "data": {
              "consultaCredito": {
                  "resumoConsulta" : {
                    "cliente": "",
                    "codigoConsulta": "COD_CONSULTA",
                    "pdf": "URL_DO_PDF_GERAL"
                  },
                  "resumoConsulta": {
                      "protestos": {
                          "valorTotal": "VALOR_EM_REAL",
                          "quantidadeTotal": "QUANTIDADE_TOTAL"
                      },
                      "pendenciasFinanceiras": {
                          "valorTotal": "VALOR_EM_REAL",
                          "quantidadeTotal": "QUANTIDADE_TOTAL"
                      },
                      "chequesSemFundo": {
                          "valorTotal": "VALOR_EM_REAL",
                          "quantidadeTotal": "QUANTIDADE_TOTAL"
                      },
                      "chequesSustados": {
                          "valorTotal": "VALOR_EM_REAL",
                          "quantidadeTotal": "QUANTIDADE_TOTAL"
                      }
                  },
                  "dadosCadastrais": {
                      "razaoSocial": "RAZAO_SOCIAL",
                      "nomeFantasia": "NOME_FANTASIA",
                      "situacao": "SITUACAO",
                      "inscricaoEstadual": "I.E.",
                      "naturezaJuridica": "NATUREZA_JURIDICA",
                      "ramoAtividade": "RAMO_ATIVIDADE",
                      "cnpj": "CNPJ",
                      "dataFundacao": "DD/MM/YYYY",
                      "capitalInicial": "VALOR_EM_REAL",
                      "endereco": "ENDERECO",
                      "bairro": "BAIRRO",
                      "cidade": "CIDADE",
                      "uf": "UF",
                      "cep": "CEP"
                  },
                  "score": {
                      "score": "SCORE",
                      "probabilidade": "PROBABILIDADE",
                      "mensagem": "MENSAGEM"
                  },
                  "protestos": [
                      {
                          "data": "DD/MM/YYYY",
                          "valor": "VALOR_EM_REAL",
                          "cartorio": "CARTORIO",
                          "cidade": "CIDADE",
                          "estado": "ESTADO"
                      },
                  ],
                  "pendenciasFinanceiras": [
                      {
                          "data": "DD/MM/YYYY",
                          "valor": "VALOR_EM_REAL",
                          "informante": "NOME_BANCO",
                          "tipoPendencia": "XX",
                          "cidade": "CIDADE"
                      },
                  ],
                  "chequesSemFundo": [
                      {
                          "data": "DD/MM/YYYY",
                          "banco": "NOME_BANCO",
                          "agencia": "NUM_AGENCIA",
                          "numeroCheque": "NUM_CHEQUE",
                          "valor": "VALOR",
                          "motivo": "MOTIVO"
                      }
                  ],
                  "chequesSustados": [
                    {
                      "data": "DD/MM/YYYY",
                      "banco": "NOME_BANCO",
                      "agencia": "NUM_AGENCIA",
                      "numeroCheque": "NUM_CHEQUE",
                      "valor": "VALOR",
                      "motivo": "MOTIVO"
                    }
                  ],
                  "historicoConsultas": [
                      {
                          "data": "DD/MM/YYYY",
                          "empresa": "NOME_EMPRESA"
                      },
                  ]
          }
    }`}
        language={"json"}
        showLineNumbers={true}
        wrapLines
        theme={dracula}
      />
    </Card>
  );
};

export default DocDefineRisco;
