import React from "react";
import { Alert, Badge, Card, Table } from "flowbite-react";
import { CodeBlock, CopyBlock, dracula } from "react-code-blocks";
import { QUERIES } from "../../config/apiConfig";
import TitleStripe from "../common/TitleStripe";

const DocAcertaEssencial = ({ token }) => {
  return (
    <Card className={`w-full mt-3`}>
      <div className="mb-4">
        <h2 className="title-syncx">Documentação da API: Positivo Plus CPF</h2>
        <br /> Esta documentação descreve como integrar-se à API utilizando um
        método <b>GET</b> com autenticação baseada em token. <br />
        Siga as instruções abaixo para enviar uma solicitação autenticada e
        entender a resposta da API.
        <br />
        <br />
        Endpoint URL:{" "}
        <Badge className="inline" color="indigo">
          https://app.acessopositivo.com.br/api{QUERIES.GET_ACERTA_ESSENCIAL}
          /:CPF
        </Badge>{" "}
        <br />
        Método:{" "}
        <Badge className="inline" color="indigo">
          GET
        </Badge>
        <br />
        Autenticação:{" "}
        <Badge className="inline" color="indigo">
          Bearer Token
        </Badge>
        <div className="font-semibold mt-5">Token:</div>
        <CopyBlock text={token} theme={dracula} codeBlock />
        <Alert withBorderAccent className="mt-2" color="warning">
          Não divulgue esse token com ninguém! O token é valido por 2 anos. Caso
          o token expire, entrar em contato diretamente com o suporte.
        </Alert>
        <div className="mt-4">
          <TitleStripe>Parâmetros da requisição</TitleStripe>
          <div className="overflow-x-auto shadow-lg rounded-lg mt-0 md:mt-5">
            <Table striped>
              <Table.Head>
                <Table.HeadCell className="text-center bg-gray-300">
                  Parâmetro
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Tipo
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Obrigatório
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Observações
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    CPF
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Texto
                  </Table.Cell>
                  <Table.Cell className="text-center text-red-500">
                    Sim
                  </Table.Cell>
                  <Table.Cell className="text-center">
                    Informar o CNPJ a ser consultado no parâmetro da URL.
                  </Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    generatePDF
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Texto (true|false)
                  </Table.Cell>
                  <Table.Cell className="text-center">Não</Table.Cell>
                  <Table.Cell className="text-center">
                    Caso queira gerar o pdf e retornar junto a resposta, colocar
                    o parametro como true
                  </Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    hasHeaderPDF
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Texto (true|false)
                  </Table.Cell>
                  <Table.Cell className="text-center">Não</Table.Cell>
                  <Table.Cell className="text-center">
                    Caso queira esconder o cabeçalho da empresa no PDF, colocar
                    o parametro como false
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>
        <br />
        <span className="text-sm -mt-2 block italic">
          Obs: Envie o token de autenticação no cabeçalho da requisição.
        </span>
        <div className="mt-4">
          <TitleStripe>Parâmetros da resposta</TitleStripe>
          <div className="overflow-x-auto shadow-lg rounded-lg mt-0 md:mt-5">
            <Table striped>
              <Table.Head>
                <Table.HeadCell className="text-center bg-gray-300">
                  Chave
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Tipo
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Observações
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                    dadosCadastrais
                  </Table.Cell>
                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                    Objeto
                  </Table.Cell>
                  <Table.Cell className="text-center">
                    Dados Cadastrais
                  </Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                    resumoConsulta
                  </Table.Cell>
                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                    Objeto
                  </Table.Cell>
                  <Table.Cell className="text-center">
                    Resumo da consulta, caso haja pendências, irá aparecer o
                    resumo
                  </Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                    Score
                  </Table.Cell>
                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                    Objeto
                  </Table.Cell>
                  <Table.Cell className="text-center">
                    Score de crédito
                  </Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                    pendenciasFinanceiras
                  </Table.Cell>
                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                    Array
                  </Table.Cell>
                  <Table.Cell className="text-center">
                    Lista de todas as pendências financeiras
                  </Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                    protestos
                  </Table.Cell>
                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                    Array
                  </Table.Cell>
                  <Table.Cell className="text-center">
                    Lista de todas os protestos
                  </Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                    chequesSemFundo
                  </Table.Cell>
                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                    Array
                  </Table.Cell>
                  <Table.Cell className="text-center">
                    Lista de todas os cheques sem fundo
                  </Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                    chequesSustados
                  </Table.Cell>
                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                    Array
                  </Table.Cell>
                  <Table.Cell className="text-center">
                    Lista de todas os cheques sustados
                  </Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                    historicoConsultas
                  </Table.Cell>
                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                    Array
                  </Table.Cell>
                  <Table.Cell className="text-center">
                    Lista de todas as últimas consultas
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
      <TitleStripe>Exemplo de resposta</TitleStripe>
      <CodeBlock
        text={`{
          "consultaCredito": {
            "resumoConsulta" : {
              "cliente": "",
              "codigoConsulta": "COD_CONSULTA",
              "pdf": "URL_DO_PDF_GERAL"
            },
            "dadosCadastrais": {
              "uf": "UF",
              "cep": "CEP",
              "cpf": "CPF",
              "nome": "NOME",
              "sexo": "SEXO",
              "email": "EMAIL",
              "bairro": "BAIRRO",
              "cidade": "CIDADE",
              "nomeMae": "NOME_DA_MAE",
              "endereco": "ENDERECO",
              "numeroRG": "NUMERO_RG",
              "situacao": "SITUACAO_CPF",
              "telefone": "TELEFONE",
              "regiaoCPF": "REGIAO_CPF",
              "estadoCivil": "ESTADO_CIVIL",
              "grauInstrucao": "GRAU_DE_INTRUCAO",
              "tituloEleitor": "TITULO_ELEITOR",
              "dataNascimento": "DD/MM/YYYY",
              "rendaPresumida": "RENDA_PRESUMIDA",
              "numeroDependentes": "NUM_DEPENDENTES",
              "textoRendaPresumida": "TEXTO_RENDA_PRESUMIDA"
            },
            "resumoConsulta": {
              "protestos": {
                  "valorTotal": "VALOR_TOTAL",
                  "quantidadeTotal": "QUANTIDADE_TOTAL"
              },
              "chequesSemFundo": {
                  "quantidadeTotal": "QUANTIDADE_TOTAL"
              },
              "chequesSustados": {
                  "quantidadeTotal": "QUANTIDADE_TOTAL"
              },
              "pendenciasFinanceiras": {
                  "valorTotal": "VALOR_TOTAL",
                  "quantidadeTotal": "QUANTIDADE_TOTAL"
              }
          },
              "score": {
                  "score": "SCORE",
                  "mensagem": "MENSAGEM_SCORE",
                  "probabilidade": "PROBABILIDADE"
              },
              "protestos": [
                  {
                      "data": "DD/MM/YYYY",
                      "valor": "VALOR",
                      "cidade": "CIDADE",
                      "cartorio": "CARTORIO",
                      "tipoDocumento": "TIPO_DOC",
                      "numeroDocumento": "NUMERO_DOC"
                  },
              "pendenciasFinanceiras": [
                    {
                        "data": "DD/MM/YYYY",
                        "valor": "VALOR",
                        "cidade": "CIDADE",
                        "situacao": "SITUACAO",
                        "informante": "INFORMANTE",
                        "tipoPendencia": "TIPO_PENDENCIA",
                        "documentoOrigem": "DOC_ORIGEM"
                    },
                ]
              "chequesSemFundo": [],
              "chequesSustados": [],
              "historicoConsultas": [
                  {
                      "data": "DD/MM/YYYY",
                      "empresa": "EMPRESA"
                  },
              ],
          }
      }`}
        language={"json"}
        showLineNumbers={true}
        wrapLines
        theme={dracula}
      />
    </Card>
  );
};

export default DocAcertaEssencial;
