import React, { useEffect, useState } from "react";
import { Card } from "flowbite-react";
import { USERS } from "../../config/apiConfig";
import { useQuery } from "react-query";
import axios from "../../config/axiosInstance";
import CadastralPFQuery from "../queries/CadastralPFQuery";
import { AiOutlineFileSearch } from "react-icons/ai";
import DrawerLoading from "../DrawerLoading";
import ForbiddenQueriesPermission from "../common/ForbiddenQueriesPermission";
import LocalizaCPFQuery from "../queries/LocalizaCPFQuery";
import LocalizaCNPJQuery from "../queries/LocalizaCNPJQuery";
import LocalizaTelefoneQuery from "../queries/LocalizaTelefoneQuery";
import CadastralPJQuery from "../queries/CadastralPJQuery";
import { IoSearch } from "react-icons/io5";

const QueriesCadastralTable = () => {
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("ALL");

  const {
    isLoading,
    error,
    data: userQueryPermissions,
  } = useQuery(["userQueryPermissions"], async () => {
    const { data: items } = await axios.get(
      `${USERS.GET_USER_QUERY_PERMISSIONS}`
    );

    return items.data.filter((query) => query.categoryId === 2);
  });

  useEffect(() => {
    if (selectedQuery) {
      const matchingQuery = userQueryPermissions.find(
        (query) => query.id === selectedQuery?.id
      );
      setSelectedPrice(matchingQuery?.price);
    }
  }, [selectedQuery]);

  const filteredQueries = userQueryPermissions?.filter((query) =>
    query?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const queries = [
    {
      id: 4,
      name: "Cadastral + Completa PF",
      component: (
        <CadastralPFQuery query={selectedQuery} selectedPrice={selectedPrice} />
      ),
      type: "CPF",
    },
    {
      id: 6,
      name: "Cadastral + Completa PJ",
      component: (
        <CadastralPJQuery query={selectedQuery} selectedPrice={selectedPrice} />
      ),
      type: "CNPJ",
    },
    {
      id: 11,
      name: "Localiza Telefone",
      component: (
        <LocalizaTelefoneQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "ALL",
    },
    {
      id: 12,
      name: "Localiza CPF",
      component: (
        <LocalizaCPFQuery query={selectedQuery} selectedPrice={selectedPrice} />
      ),
      type: "CPF",
    },
    {
      id: 13,
      name: "Localiza CNPJ",
      component: (
        <LocalizaCNPJQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "CNPJ",
    },
  ];

  return (
    <div className="w-100 flex flex-col lg:flex-row relative">
      {isLoading && <DrawerLoading />}
      {userQueryPermissions?.length > 0 ? (
        <>
          <div className="w-full lg:w-3/12 p-4 flex flex-col gap-3">
            <h2 className="title-syncx text-lg">Consultas cadastrais</h2>
            <div className="border-none rounded-md bg-secondary p-2 shadow-lg flex items-center gap-1 px-3">
              <IoSearch color="white" fontSize={20} />
              <input
                className="border-none rounded-md bg-secondary w-full placeholder-white focus:outline-none focus:border-none text-white text-sm"
                type="text"
                placeholder="Busque aqui sua consulta"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} // Atualiza o valor da busca
              />
            </div>
            <ul className="flex -mb-4 ml-3 text-xs">
              <li
                className={`border border-gray-200 p-3 rounded px-4 cursor-pointer ${
                  activeTab === "ALL"
                    ? "text-blue-800 font-semibold bg-gray-200"
                    : "bg-gray-100"
                }`}
                onClick={() => setActiveTab("ALL")}
              >
                Todas
              </li>
              <li
                className={`border border-gray-200 p-3 rounded px-4 cursor-pointer ${
                  activeTab === "CPF"
                    ? "text-blue-800 font-semibold bg-gray-200"
                    : "bg-gray-100"
                }`}
                onClick={() => setActiveTab("CPF")}
              >
                CPF
              </li>
              <li
                className={`border border-gray-200 p-3 rounded px-4 cursor-pointer ${
                  activeTab === "CNPJ"
                    ? "text-blue-800 font-semibold bg-gray-200"
                    : "bg-gray-100"
                }`}
                onClick={() => setActiveTab("CNPJ")}
              >
                CNPJ
              </li>
            </ul>
            <div
              className="bg-gray-200 rounded-md shadow-md p-2 overflow-y-auto flex flex-col gap-2 styled-scrollbar"
              style={{ maxHeight: "520px" }}
            >
              {filteredQueries?.map((query) => {
                const matchedQuery = queries.find(
                  (queryToCompare) => queryToCompare.id === query.id
                );
                const isActiveTab =
                  activeTab === "ALL" ||
                  matchedQuery?.type === activeTab ||
                  matchedQuery?.type === "ALL";

                return (
                  isActiveTab && (
                    <Card
                      key={query.id}
                      className={`card-query text-blue-900 ${
                        selectedQuery?.id === query.id ? "active" : ""
                      }`}
                      onClick={() => setSelectedQuery(query)}
                    >
                      <span>{query.name}</span>
                    </Card>
                  )
                );
              })}
            </div>
          </div>
          {selectedQuery ? (
            <div className={`w-full lg:w-9/12 p-4`}>
              {
                queries.find((query) => query.id === selectedQuery?.id)
                  ?.component
              }
            </div>
          ) : (
            <div className="mt-14 w-full lg:w-9/12 text-center py-10 border-dashed border-gray-300 border-2 rounded font-semibold text-gray-500 flex items-center justify-center min-h-60 flex gap-1 flex-col">
              <AiOutlineFileSearch fontSize={30} />
              <span>Escolha uma opção ao lado para realizar uma consulta.</span>
            </div>
          )}
        </>
      ) : (
        <ForbiddenQueriesPermission />
      )}
    </div>
  );
};

export default QueriesCadastralTable;
