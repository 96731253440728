import { Icon } from "@mui/material";
import React from "react";

const Drawer = ({
  className,
  children,
  isOpen,
  onClose,
  title,
}) => {
  return (
    <div
      className={`shadow-md fixed top-0 right-0 z-50 h-screen p-4 overflow-y-auto transition-transform bg-white ${
        !isOpen ? "translate-x-full" : "transform-none"
      } ${className}`}
      tabIndex={-1}
      aria-labelledby="drawer-right-label"
      style={{ minWidth: "320px" }}
    >
      {" "}
      <h5
        id="drawer-right-label"
        className="inline-flex items-center mb-10 text-base font-semibold text-gray-500 dark:text-gray-400"
      >
        <Icon className="mr-3">info</Icon>
        {title}
      </h5>
      <button
        type="button"
        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
        onClick={() => onClose(false)}
      >
        <svg
          className="w-3 h-3"
          ariaHidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
        <span className="sr-only">Fechar menu</span>
      </button>
      <div>{children}</div>
    </div>
  );
};

export default Drawer;
