import React from "react";
import { Alert, Badge, Card, Table } from "flowbite-react";
import { CodeBlock, CopyBlock, dracula } from "react-code-blocks";
import TitleStripe from "../common/TitleStripe";
import { QUERIES } from "../../config/apiConfig";

const DocCompletaPlus = ({ token }) => {
  return (
    <Card className={`w-full mt-3`}>
      <div className="mb-4">
        <h2 className="title-syncx">Documentação da API: Completa Plus</h2>
        <br /> Esta documentação descreve como integrar-se à API utilizando um
        método <b>GET</b> com autenticação baseada em token. <br />
        Siga as instruções abaixo para enviar uma solicitação autenticada e
        entender a resposta da API.
        <br />
        <br />
        Endpoint URL:{" "}
        <Badge className="inline" color="indigo">
          https://app.acessopositivo.com.br/api{QUERIES.GET_COMPLETA_PLUS_CPF}
          /:CPF
        </Badge>{" "}
        <br />
        Método:{" "}
        <Badge className="inline" color="indigo">
          GET
        </Badge>
        <br />
        Autenticação:{" "}
        <Badge className="inline" color="indigo">
          Bearer Token
        </Badge>
        <br />
        <div className="font-semibold mt-3">Token:</div>
        <CopyBlock text={token} theme={dracula} codeBlock />
        <Alert className="mt-2" color="warning" withBorderAccent>
          Não divulgue esse token com ninguém! O token é valido por 2 anos. Caso
          o token expire, entrar em contato diretamente com o suporte.
        </Alert>
        <span className="text-sm mt-2 block italic">
          Obs: Envie o token de autenticação no cabeçalho da requisição.
        </span>
        <div className="mt-4">
          <TitleStripe>Parâmetros da requisição</TitleStripe>
          <div className="overflow-x-auto shadow-lg rounded-lg mt-0 md:mt-5">
            <Table striped>
              <Table.Head>
                <Table.HeadCell className="text-center bg-gray-300">
                  Parâmetro
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Tipo
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Obrigatório
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Observações
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    CPF/CNPJ
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Texto
                  </Table.Cell>
                  <Table.Cell className="text-center text-red-500">
                    Sim
                  </Table.Cell>
                  <Table.Cell className="text-center">
                    Informar o CPF/CNPJ a ser consultado no parâmetro da URL
                    (Somente número).
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>
        <div className="mt-4">
          <TitleStripe>Parâmetros da resposta</TitleStripe>
          <div className="overflow-x-auto shadow-lg rounded-lg mt-0 md:mt-5">
            <Table striped>
              <Table.Head>
                <Table.HeadCell className="text-center bg-gray-300">
                  Chave
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Tipo
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Observações
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    nome
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    String
                  </Table.Cell>
                  <Table.Cell className="text-center"></Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    data
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    String
                  </Table.Cell>
                  <Table.Cell className="text-center"></Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Pendências Financeiras
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Array
                  </Table.Cell>
                  <Table.Cell className="text-center"></Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    CADIN
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Array
                  </Table.Cell>
                  <Table.Cell className="text-center"></Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Empresas
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Array
                  </Table.Cell>
                  <Table.Cell className="text-center"></Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Telefones
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Array
                  </Table.Cell>
                  <Table.Cell className="text-center"></Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Protestos
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Array
                  </Table.Cell>
                  <Table.Cell className="text-center"></Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
      <CodeBlock
        text={`{
          "doc": "22083403886",
          "nome": "SHEILA CRISTINA GARCIA XAVIER",
          "data": "12/11/2024 17:35:10",
          "protestos": [],
          "pendenciasFinanceiras": [
              {
                  "data": "05/08/2024",
                  "tipo": " T",
                  "nome": " SP-SPO/CLICKNET TELECOMUNICACOES PRO",
                  "valor": " R$ 99,90",
                  "cidade": " Cr�dito SAO PAULO",
                  "uf": " SP",
                  "contrato": " 875993161095",
                  "data_disponivel": ""
              },
          ],
          "cadin": [],
          "dados_gerais": {
              "primeira_ocorrencia": "19/06/2020",
              "ultima_ocorrencia": "10/05/2022",
              "quantidade": 3
          },
          "cheques": [],
          "telefones": [
              {
                  "DDD": "19",
                  "TELEFONE": "981761059",
                  "TIPO_TELEFONE": "3",
                  "TIPO_DS": "TELEFONE MÓVEL",
                  "DT_INCLUSAO": "27/07/2017",
                  "DT_ATUALIZACAO": null,
                  "DT_INFORMACAO": null,
                  "FONE_NOTA": "11",
                  "SIGILO": null,
                  "PRIORIDADE": "1",
                  "CLASSIFICACAO": "A2",
                  "CLASSIFICACAO_DS": "BASE DE PARCERIAS"
              },
          ],
          "enderecos": [
              {
                  "LOGR_TIPO": "AV",
                  "LOGR_TITULO": "",
                  "LOGR_NOME": "JOAO ERBOLATO",
                  "LOGR_NUMERO": "861",
                  "LOGR_COMPLEMENTO": "BL MELOTTI GEOVANI",
                  "BAIRRO": "JD CHAPADAO",
                  "CIDADE": "CAMPINAS",
                  "UF": "SP",
                  "CEP": "13070071",
                  "CEP_NOTA": "10",
                  "ENDERECO": "AV JOAO ERBOLATO 861 BL MELOTTI GEOVANI",
                  "DT_INCLUSAO": "16/05/2019",
                  "DT_ATUALIZACAO": "30/08/2019",
                  "DT_INFORMACAO": null,
                  "LATITUDE": "-22.887933540000000",
                  "LONGITUDE": "-47.085762920000000",
                  "PRIORIDADE": "1",
                  "CLASSIFICACAO": "B",
                  "TIPO_ENDERECO_ID": "Comercial"
              },
          ],
          "empresas": [
              {
                  "cnpj": "14813733000150",
                  "razaosocial": "GARCIA E PEREIRA SERV ADMINISTRATIVOS LTDA",
                  "fantasia": "GARCIA E PEREIRA SERV ADMINISTRATIVOS LTDA",
                  "fundacao": "2011-12-19 00:00:00.000",
                  "cidade": "CAMPINAS",
                  "uf": "SP",
                  "cnae": "82199",
                  "cnaesecun": "8219999",
                  "porcentagem": "1.00"
              }
          ]
      }`}
        language={"json"}
        showLineNumbers={true}
        wrapLines
        theme={dracula}
      />
    </Card>
  );
};

export default DocCompletaPlus;
