import React, { useState } from "react";
import { Badge, Button, Pagination, Table } from "flowbite-react";
import { FaArrowsLeftRight } from "react-icons/fa6";
import axios from "../../config/axiosInstance";
import { useQuery, useQueryClient } from "react-query";
import { ADMIN, CATEGORIES } from "../../config/apiConfig";
import { TbLockSearch } from "react-icons/tb";
import Drawer from "../Drawer";
import { IoMdAdd, IoMdSearch } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import EmptyTable from "./EmptyTable";
import { MdBlock } from "react-icons/md";
import TableLoading from "../TableLoading";
import TableQueriesForm from "../forms/TableQueriesForm";
import { useApplication } from "../../contexts/ApplicationContext";
import { formatToReal } from "../../utils/utils";

const AdminTablesTable = () => {
  const queryClient = useQueryClient();
  const { user } = useApplication();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const onPageChange = (page) => setCurrentPage(page);
  const [searchName, setSearchName] = useState("");
  const [nameParam, setNameParam] = useState("");
  const [isDrawerTableQueries, setIsDrawerTableQueries] = useState(false);
  const [type, setType] = useState("CREATE");
  const [selectedTable, setSelectedTable] = useState(null);

  const {
    isLoading: isLoadingTables,
    error: errorListTables,
    data: listTables,
  } = useQuery(["listTables", currentPage, nameParam], async () => {
    const { data } = await axios.get(
      `${ADMIN.GET_TABLES}?page=${currentPage}&search=${nameParam}`
    );

    setTotalPages(data.data.totalPages || 1);
    setTotalCount(data.data.totalCount || 0);

    return data.data.data;
  });

  const {
    isLoading: isLoadingCategories,
    error: errorCategories,
    data: categories,
  } = useQuery(["categories"], async () => {
    const { data } = await axios.get(
      `${CATEGORIES.GET_ALL}?itemsPerPage=${-1}`
    );

    return data.data.data;
  });

  const handleSearchName = (e) => {
    e.preventDefault();
    setNameParam(searchName);
  };

  const handleEditTableQueries = (table, type) => {
    setType(type);
    setSelectedTable(table);
    setIsDrawerTableQueries((prevState) => !prevState);
  };

  return (
    <>
      <div className="flex justify-end mb-3 mt-2">
        <Button
          className="bg-primary"
          variant="contained"
          onClick={() => handleEditTableQueries(null, "CREATE")}
        >
          <IoMdAdd className="block md:hidden text-2xl" />

          <span className="hidden md:block">Criar tabela</span>
        </Button>
      </div>
      <form
        className="flex gap-1 mt-5 w-full pl-3 pr-2 py-2 md:py-1 rounded-md shadow-sm bg-gray-300"
        onSubmit={handleSearchName}
      >
        <input
          type="text"
          placeholder="Busque por nome da tabela..."
          className="border-0 py-1 text-sm mt-1 focus:outline-none focus:ring-0 focus:border-gray-300 px-0 w-full bg-gray-300 placeholder:text-gray-700"
          onChange={(e) => setSearchName(e.target.value)}
          value={searchName}
        />
        <Button
          size="sm"
          className="flex items-center bg-secondary w-20"
          type="submit"
        >
          <IoMdSearch />
        </Button>
      </form>

      {listTables?.length > 0 || isLoadingTables ? (
        <>
          <small
            className="gap-2 items-center mt-5 mb-1 flex md:hidden justify-center"
            style={{ fontSize: 10 }}
          >
            <span>
              Deslize para o lado para visualizar todas as informações
            </span>{" "}
            <FaArrowsLeftRight />
          </small>
          <div className="overflow-x-auto shadow-md rounded-lg mt-0 md:mt-5">
            <Table striped className="text-xs xl:text-sm">
              <Table.Head>
                <Table.HeadCell className="text-center bg-gray-300">
                  Tabela
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  VCM
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Qtd. Clientes vinculados
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Status
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Ações
                </Table.HeadCell>
              </Table.Head>

              <Table.Body className="divide-y">
                {!isLoadingTables ? (
                  <>
                    {listTables?.length > 0 && (
                      <>
                        {listTables.map((table, index) => (
                          <Table.Row
                            key={index}
                            className="bg-white dark:border-gray-700 dark:bg-gray-800"
                          >
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-1">
                              {table.name}
                            </Table.Cell>
                            <Table.Cell className="text-center py-1">
                              {table.vcm ? formatToReal(table.vcm) : "-"}
                            </Table.Cell>
                            <Table.Cell className="text-center py-1">
                              0
                            </Table.Cell>
                            <Table.Cell className="text-center py-1">
                              {table.isActive == 1 ? (
                                <Badge color="green" className="mx-auto w-fit">
                                  Ativo
                                </Badge>
                              ) : (
                                <Badge
                                  color="failure"
                                  className="mx-auto w-fit"
                                >
                                  Inativo
                                </Badge>
                              )}
                            </Table.Cell>
                            <Table.Cell className="text-center py-1">
                              <div className="flex gap-2 justify-center items-center">
                                <Button
                                  className="p-0 bg-primary"
                                  onClick={() =>
                                    handleEditTableQueries(table, "EDIT")
                                  }
                                >
                                  <FaRegEdit fontSize="16" />
                                </Button>
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  <TableLoading cols={5} />
                )}
              </Table.Body>
            </Table>
          </div>

          {totalCount > 0 && (
            <div className="mt-3 text-center text-sm">
              Foram encontrados {totalCount} registros no total
            </div>
          )}

          {totalPages > 1 && (
            <div className="flex overflow-x-auto justify-center mt-3">
              <Pagination
                layout="pagination"
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
                previousLabel=""
                nextLabel=""
                showIcons
              />
            </div>
          )}
        </>
      ) : (
        <EmptyTable icon="search_off">Nenhuma tabela encontrada</EmptyTable>
      )}
      <Drawer
        title={`${type === "CREATE" ? "Criar" : "Editar"} Tabela de consultas`}
        onClose={setIsDrawerTableQueries}
        isOpen={isDrawerTableQueries}
        className={"w-full md:w-4/6 lg:w-2/6"}
      >
        <TableQueriesForm
          selectedTable={selectedTable}
          categories={categories}
          onClose={setIsDrawerTableQueries}
          fetchTables={() => {
            queryClient.invalidateQueries(["listTables"]);
          }}
          type={type}
        />
      </Drawer>
    </>
  );
};

export default AdminTablesTable;
