import { Badge, Table } from "flowbite-react";
import { formatToReal } from "../../../utils/utils";

const ProtestosDirCartorio = ({ protestosDirCartorio, quantidadeTotal }) => {
  return (
    <>
      <div className="flex gap-6">
        {quantidadeTotal && (
          <div className="flex items-center gap-2">
            <span className="font-semibold text-red-700">Quantidade: </span>
            <Badge color="failure">{quantidadeTotal}</Badge>
          </div>
        )}
      </div>
      <div className="overflow-x-auto border-gray-100 border rounded-lg mt-3">
        <Table striped className="text-sm">
          <Table.Head>
            <Table.HeadCell
              className="text-black"
              style={{ background: "#76B9FB" }}
            >
              Cartório
            </Table.HeadCell>
            <Table.HeadCell
              className="text-black text-center"
              style={{ background: "#76B9FB" }}
            >
              Cidade
            </Table.HeadCell>
            <Table.HeadCell
              className="text-black text-center"
              style={{ background: "#76B9FB" }}
            >
              UF
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black "
              style={{ background: "#76B9FB" }}
            >
              Valor
            </Table.HeadCell>
          </Table.Head>
          {protestosDirCartorio?.length > 0 && (
            <Table.Body className="divide-y">
              {protestosDirCartorio?.map((protesto, index) => (
                <Table.Row
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  key={index}
                >
                  <Table.Cell className="font-medium text-black dark:text-white py-1">
                    <div>{protesto?.cartorio}</div>
                  </Table.Cell>
                  <Table.Cell className=" py-1 text-black text-center">
                    {protesto?.cidade}
                  </Table.Cell>
                  <Table.Cell className=" py-1 text-black text-center">
                    {protesto?.estado}
                  </Table.Cell>
                  <Table.Cell className="text-center py-1 text-black w-36">
                    {protesto?.valor}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </div>
    </>
  );
};

export default ProtestosDirCartorio;
