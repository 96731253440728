import React from "react";
import { Alert, Badge, Card, Table } from "flowbite-react";
import { CodeBlock, CopyBlock, dracula } from "react-code-blocks";
import TitleStripe from "../common/TitleStripe";
import { QUERIES } from "../../config/apiConfig";

const DocSCRBacen = ({ token }) => {
  return (
    <Card className={`w-full mt-3`}>
      <div className="mb-4">
        <h2 className="title-syncx">Documentação da API: SCR Bacen + Score</h2>
        <br /> Esta documentação descreve como integrar-se à API utilizando um
        método <b>GET</b> com autenticação baseada em token. <br />
        Siga as instruções abaixo para enviar uma solicitação autenticada e
        entender a resposta da API.
        <br />
        <br />
        Endpoint URL:{" "}
        <Badge className="inline" color="indigo">
          https://app.acessopositivo.com.br/api{QUERIES.GET_SCR_BACEN_SCORE}
          /:document
        </Badge>{" "}
        <br />
        Método:{" "}
        <Badge className="inline" color="indigo">
          GET
        </Badge>
        <br />
        Autenticação:{" "}
        <Badge className="inline" color="indigo">
          Bearer Token
        </Badge>
        <br />
        <div className="font-semibold mt-3">Token:</div>
        <CopyBlock text={token} theme={dracula} codeBlock />
        <Alert className="mt-2" color="warning" withBorderAccent>
          Não divulgue esse token com ninguém! O token é valido por 2 anos. Caso
          o token expire, entrar em contato diretamente com o suporte.
        </Alert>
        <span className="text-sm mt-2 block italic">
          Obs: Envie o token de autenticação no cabeçalho da requisição.
        </span>
      </div>
      <CodeBlock
        text={`{
          "status": "success",
          "data": {
              "HEADER": {
                  "CONTROLE": {
                      "OCORRENCIAS": [
                          {
                              "FONTE": "118",
                              "STATUS": "1",
                              "CONTEUDO": "284"
                          }
                      ],
                      "QUANTIDADE_OCORRENCIAS": "1"
                  },
                  "PARAMETROS": {
                      "CPFCNPJ": "49711968894"
                  },
              },
              "CREDCADASTRAL": {
                  "RELATORIO_SCR": {
                      "SCORE": {
                          "FAIXA": "RUIM",
                          "PONTUACAO": "250"
                      },
                      "DOCUMENTO": "49711968894",
                      "OPERACOES": [
                          {
                              "TOTAL": "3748,05",
                              "MODALIDADE": {
                                  "CODIGO": "02",
                                  "DESCRICAO": "EMPRESTIMOS"
                              },
                              "PERCENTUAL": "51,57",
                              "VENCIMENTOS": [
                                  {
                                      "VALOR": "21,94",
                                      "CODIGO": "V110",
                                      "DESCRICAO": "CREDITOS A VENCER ATE 30 DIAS",
                                      "QTD_MESES": "1",
                                      "PERCENTUAL": "0,30",
                                      "RESTRITIVO": "0"
                                  },
                              ],
                              "SUB_MODALIDADE": {
                                  "CODIGO": "03",
                                  "DESCRICAO": "CREDITO PESSOAL - SEM CONSIGNACAO EM FOLHA DE PAGAMENTO."
                              },
                              "VARIACAO_CAMBIAL": "0"
                          },
                          {
                              "TOTAL": "716,31",
                              "MODALIDADE": {
                                  "CODIGO": "02",
                                  "DESCRICAO": "EMPRESTIMOS"
                              },
                              "PERCENTUAL": "9,86",
                              "VENCIMENTOS": [
                                  {
                                      "VALOR": "19,45",
                                      "CODIGO": "V110",
                                      "DESCRICAO": "CREDITOS A VENCER ATE 30 DIAS",
                                      "QTD_MESES": "1",
                                      "PERCENTUAL": "0,27",
                                      "RESTRITIVO": "0"
                                  }
                              ],
                              "SUB_MODALIDADE": {
                                  "CODIGO": "04",
                                  "DESCRICAO": "CREDITO ROTATIVO VINCULADO A CARTAO DE CREDITO"
                              },
                              "VARIACAO_CAMBIAL": "0"
                          },
                          {
                              "TOTAL": "972,38",
                              "MODALIDADE": {
                                  "CODIGO": "02",
                                  "DESCRICAO": "EMPRESTIMOS"
                              },
                              "PERCENTUAL": "13,38",
                              "VENCIMENTOS": [
                                  {
                                      "VALOR": "284,97",
                                      "CODIGO": "V120",
                                      "DESCRICAO": "CREDITOS A VENCER DE 31 A 60 DIAS",
                                      "QTD_MESES": "2",
                                      "PERCENTUAL": "3,92",
                                      "RESTRITIVO": "0"
                                  }
                              ],
                              "SUB_MODALIDADE": {
                                  "CODIGO": "10",
                                  "DESCRICAO": "CARTAO DE CREDITO – COMPRA, FATURA PARCELADA OU SAQUE FINANCIADO PELA INSTITUICAO EMITENTE DO CARTAO"
                              },
                              "VARIACAO_CAMBIAL": "0"
                          },
                          {
                              "TOTAL": "1810,64",
                              "MODALIDADE": {
                                  "CODIGO": "02",
                                  "DESCRICAO": "EMPRESTIMOS"
                              },
                              "PERCENTUAL": "24,91",
                              "VENCIMENTOS": [
                                  {
                                      "VALOR": "3,45",
                                      "CODIGO": "V110",
                                      "DESCRICAO": "CREDITOS A VENCER ATE 30 DIAS",
                                      "QTD_MESES": "1",
                                      "PERCENTUAL": "0,05",
                                      "RESTRITIVO": "0"
                                  },
                              ],
                              "SUB_MODALIDADE": {
                                  "CODIGO": "18",
                                  "DESCRICAO": "CARTAO DE CREDITO - NAO MIGRADO 50 RECEBIVEIS ADQUIRIDOS"
                              },
                              "VARIACAO_CAMBIAL": "0"
                          },
                          {
                              "TOTAL": "20,15",
                              "MODALIDADE": {
                                  "CODIGO": "13",
                                  "DESCRICAO": "OUTROS CREDITOS"
                              },
                              "PERCENTUAL": "0,28",
                              "VENCIMENTOS": [
                                  {
                                      "VALOR": "20,15",
                                      "CODIGO": "V110",
                                      "DESCRICAO": "CREDITOS A VENCER ATE 30 DIAS",
                                      "QTD_MESES": "1",
                                      "PERCENTUAL": "0,28",
                                      "RESTRITIVO": "0"
                                  }
                              ],
                              "SUB_MODALIDADE": {
                                  "CODIGO": "04",
                                  "DESCRICAO": "CARTAO DE CREDITO - COMPRA A VISTA E PARCELADO LOJISTA"
                              },
                              "VARIACAO_CAMBIAL": "0"
                          }
                      ],
                      "CONSOLIDADO": {
                          "PREJUIZO": {
                              "VALOR": "0,00",
                              "OPERACOES": [],
                              "PERCENTUAL": "0,00"
                          },
                          "LIMITE_CREDITO": {
                              "VALOR": "0,00",
                              "OPERACOES": [],
                              "PERCENTUAL": "0,00"
                          },
                          "CREDITO_AVENCER": {
                              "VALOR": "5093,99",
                              "OPERACOES": [
                                  {
                                      "VALOR": "64,99",
                                      "DESCRICAO": "CREDITOS A VENCER ATE 30 DIAS",
                                      "QTD_MESES": "1",
                                      "PERCENTUAL": "0,89"
                                  },
                              ],
                              "PERCENTUAL": "70,09"
                          },
                          "CREDITO_VENCIDO": {
                              "VALOR": "2173,54",
                              "OPERACOES": [
                                  {
                                      "VALOR": "25,39",
                                      "DESCRICAO": "CREDITOS VENCIDOS DE 15 A 30 DIAS",
                                      "QTD_MESES": "1",
                                      "PERCENTUAL": "0,35"
                                  },
                              ],
                              "PERCENTUAL": "29,91"
                          }
                      },
                      "STATUS_RETORNO": {
                          "CODIGO": "1",
                          "DESCRICAO": "CONSULTA CONCLUIDA COM SUCESSO"
                      },
                      "TIPO_DOCUMENTO": "FISICA",
                      "NOME_RAZAO_SOCIAL": "",
                      "DATABASE_CONSULTADA": "10/2024",
                      "NASCIMENTO_FUNDACAO": "",
                      "COOBRIGACAO_ASSUMIDA": "0,00",
                      "COOBRIGACAO_RECEBIDA": "0,00",
                      "QUANTIDADE_OPERACOES": "10",
                      "RISCO_INDIRETO_VENDOR": "0,00",
                      "QUANTIDADE_INSTITUICOES": "5",
                      "DATA_INICIO_RELACIONAMENTO": "11/08/2020",
                      "PERCENTUAL_VOLUME_PROCESSADO": "0,00",
                      "QUANTIDADE_OPERACOES_SUBJUDICE": "0",
                      "RESPONSABILIDADE_TOTAL_SUBJUDICE": "0",
                      "PERCENTUAL_DOCUMENTOS_PROCESSADOS": "0,00",
                      "QUANTIDADE_OPERACOES_DISCORDANCIA": "0",
                      "RESPONSABILIDADE_TOTAL_DISCORDANCIA": "0"
                  },
                  "INFORMACOES_ALERTAS_RESTRICOES": {
                      "OCORRENCIAS": [],
                      "STATUS_RETORNO": {
                          "CODIGO": "1",
                          "DESCRICAO": "CONSULTA CONCLUIDA COM SUCESSO"
                      },
                      "QUANTIDADE_OCORRENCIA": "0"
                  }
              },
              "resumoRetorno": {
                  "cliente": "",
                  "codigoConsulta": 3,
                  "pdf": "",
                  "document": "49711968894",
                  "dataConsulta": "10/12/2024 | 23:13"
              }
          }
      }`}
        language={"json"}
        showLineNumbers={true}
        wrapLines
        theme={dracula}
      />
    </Card>
  );
};

export default DocSCRBacen;
