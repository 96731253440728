import { Badge, Table } from "flowbite-react";
import { formatToReal } from "../../../utils/utils";

const AcoesCiveis = ({
  acoesCiveis,
  quantidadeTotal,
  primeiroValor,
  valorTotal,
}) => {
  return (
    <>
      <div className="flex gap-6">
        {quantidadeTotal && (
          <div className="flex items-center gap-2">
            <span className="font-semibold text-red-700">Quantidade: </span>
            <Badge color="failure">{quantidadeTotal}</Badge>
          </div>
        )}
        {primeiroValor && (
          <div className="flex items-center gap-2">
            <span className="font-semibold text-red-700">Primeiro valor: </span>
            <Badge color="failure">{formatToReal(primeiroValor)}</Badge>
          </div>
        )}

        {valorTotal && (
          <div className="flex items-center gap-2">
            <span className="font-semibold text-red-700">Valor Total: </span>
            <Badge color="failure">{formatToReal(valorTotal)}</Badge>
          </div>
        )}
      </div>
      <div className="overflow-x-auto border-gray-100 border rounded-lg mt-3">
        <Table striped>
          <Table.Head>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Processo
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Foro/UF
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Ação
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Autor
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black px-1"
              style={{ background: "#76B9FB" }}
            >
              Distribuição
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Valor
            </Table.HeadCell>
          </Table.Head>

          {acoesCiveis?.length > 0 && (
            <Table.Body className="divide-y">
              {acoesCiveis?.map((acao, index) => (
                <Table.Row
                  key={index}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                >
                  <Table.Cell className="text-center py-1 text-black">
                    {acao?.acao || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center py-1 text-black">
                    {acao?.foro || "-"}/{acao?.uf || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center py-1 text-black">
                    {acao?.processo || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center py-1 text-black">
                    {acao?.autor || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center  px-1 py-1">
                    {acao?.distribuicao || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center py-1 text-black">
                    {formatToReal(acao?.valor || "0")}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </div>
    </>
  );
};

export default AcoesCiveis;
